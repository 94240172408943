import React from 'react';
import Container from '../__Container';

import Banner from '../__Banner';
import Why from './_Why';
import Six from './_Six';
import BannerImg from 'imgs/1d1d/pc/banner.intro.png';

export default function Intro() {
  return (
    <Container>
      <Banner banner={BannerImg} h1="平台介绍" h2s={['platform introduction']} />
      <Six />
      <Why />
    </Container>
  );
}
