import React from 'react';
import styled from 'styled-components';
import Icon from 'imgs/1d1d/pc/icon.circle.logo.png';

const Wrapper = styled.section`
  display: flex;
  align-items: flex-start;

  padding: 5.4rem 0 4rem 0;

  .txt {
    display: flex;
    flex-direction: column;
    margin-bottom: -1.3rem;
    margin-left: 4rem;
    z-index: 80;
    > h2 {
      font-size: 1.4rem;
      color: #636363;
      margin-bottom: 0.6rem;
      font-weight: bold;
    }
    > h3 {
      font-size: 0.8rem;
      color: #969696;
      margin-bottom: 1.5rem;
    }
    .con {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 41.2rem;
      height: 19.1rem;
      font-size: 0.7rem;
      color: #636363;
      padding: 0 4.8rem 0 7.8rem;
      background: #fff;
      border-radius: 5px;
      margin-left: -7.8rem;
      line-height: 1.5rem;
    }
  }
  .pic {
    position: relative;
    height: 22.6rem;
    z-index: 90;
    .ico {
      z-index: 99;
      width: 5rem;
      position: absolute;
      top: 50%;
      right: -2.5rem;
      transform: translateY(-50%);
    }
    .img {
      height: 100%;
    }
  }
  &.rev {
    flex-direction: row-reverse;
    .pic {
      .ico {
        left: -2.5rem;
      }
    }
    .txt {
      margin-right: 4rem;
      align-items: flex-end;
      .con {
        padding: 0 7.8rem 0 4.8rem;
        margin-right: -7.8rem;
      }
    }
  }
`;
export default function Banner({ title, subTitle, img, content, reverse = false }) {
  return (
    <Wrapper className={reverse ? 'rev' : ''}>
      <div className="pic">
        <img data-aos="fade-down" className="ico" src={Icon} alt="圆形图标" />
        <img data-aos="fade-right" className="img" src={img} alt="Banner" />
      </div>
      <div data-aos="fade-left" className="txt">
        <h2>{title}</h2>
        <h3>{subTitle}</h3>
        <p className="con">{content}</p>
      </div>
    </Wrapper>
  );
}
