import ImgYsxq from 'imgs/1d1d/pc/space.ysxq.png';
import ImgZnzs from 'imgs/1d1d/pc/space.znzs.png';
import ImgAqgk from 'imgs/1d1d/pc/space.aqgk.png';

const Sections = [
  {
    title: '高效招生',
    subTitle: '高效招生',
    desc:
      '壹点壹滴“幼教云”倾力打造互联网招生神器——招生王，线上线下联动招生，7x24小时互联网招生，手把手指导园所线上高效采单、线下精准关单，确保幼儿园招生有的放矢，事半功倍。',
    content: [
      {
        title: '招生方案',
        list: ['可编辑的活动模板+高转化率的创意方案，驱动家长圈的快速裂变。']
      },
      {
        title: '招生海报',
        list: ['每日提供裂变的采单海报，自动筛选出精准客户。']
      },
      {
        title: '招生微课',
        list: ['提供新颖多样、效果显著的招生活动指导。']
      },
      {
        title: '采单管理',
        list: ['更系统的收集、分析采单信息，招生数据一目了然。']
      }
    ],
    img: ImgYsxq
  },
  {
    title: '专业师训',
    subTitle: '专业师训',
    desc: '',
    content: [
      {
        title: '幼师学院',
        list: [
          '幼师学院为幼儿园各岗位提供优质、完善的培训视频。一看就懂、一学就会，阶梯考核，合格上岗，帮助园所快速、高效地让新手老师从不合格到合格，让更多的老师从合格到优秀。'
        ]
      },
      {
        title: '带班助手',
        list: [
          '围绕“带班”，从日常班级管理到教学游戏素材，从节日活动方案到班级各项工作，小、中、大分龄提供、日、周、月分时提供幼师一日工作所需。'
        ]
      },
      {
        title: '专家引领',
        list: [
          '借助互联网的力量，借助平台整合国内优秀幼教资源的力量，让幼师的专业化成长取得事半功倍的效果。'
        ]
      }
    ],
    img: ImgZnzs
  },
  {
    title: '园所管理',
    subTitle: '园所管理',
    desc:
      '“幼教云”为园所倾力打造数字化管理系统，培养懂经营、会管理的职业园长，让园所管理更轻松更高效。',
    content: [
      {
        title: '园长商学院',
        list: [
          '幼教大咖亲力打造的“园长商学院”，手把手指导园长实现专业发展和自我提升，助力园长成为全能型的管理者。'
        ]
      },
      {
        title: '家园沟通',
        list: [
          '“幼教云”帮助园所打破家园沟通黑匣子，手把手指导老师做好家长工作，老师、家长联动科学育儿。'
        ]
      },
      {
        title: '彩虹管控点',
        list: [
          '“幼教云”倾力打造10+4X园所安全彩虹管控点，让管控落在场景、落在动作，为幼儿园守住安全底线。'
        ]
      }
    ],
    img: ImgAqgk
  }
];
export default Sections;
