import icon1 from 'imgs/1d1d/pc/ketizu/home_icon_1@2x.png';
import icon2 from 'imgs/1d1d/pc/ketizu/home_icon_2@2x.png';
import icon3 from 'imgs/1d1d/pc/ketizu/home_icon_3@2x.png';
import icon4 from 'imgs/1d1d/pc/ketizu/home_icon_4@2x.png';

export const HOME_DATA = [
  {
    title: `研究背景`,
    icon: icon1,
    desc:
      '中国教育发展战略学会教师发展专业委员会高度重视“十四五”期间幼儿园的家庭教育工作。“信息化背景下的家庭教育研究”重点课题就是在这样的背景下诞生。',
    url: "/ketizu/about/#tab-1"
  },
  {
    title: `专家团队`,
    icon: icon2,
    desc: '本课题得到了中国教育发展战略学会教师发展专业委员会、行业专家的大力支持。',
    url: "/ketizu/about/#tab-2"
  },
  {
    title: `实验基地`,
    icon: icon3,
    desc: '本课题面向全国幼儿园招募课题实验基地，主要参与课程、师资、教学三方面的应用研究。',
    url: "/ketizu/about/#tab-3"
  },
  {
    title: `学会介绍`,
    icon: icon4,
    desc:
      '中国教育发展战略学会是为适应教育事业发展与改革的需要，于2005年经教育部、民政部批准成立的国家一级学会。主管单位是中华人民共和国教育部。',
    url: "/ketizu/about/#tab-4"
  }
];
