import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from './__Nav';
import Footer from './__Footer';
import GlobalStyle from './__Global.style';
import MTA from 'RootCommon/MTA';
export default function Container({ children }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 300
    });
  }, []);
  return (
    <>
      <GlobalStyle />
      <Nav />
      {children}
      <Footer />
      {process.env.NODE_ENV == 'production' && <MTA sid="500692331" />}
    </>
  );
}
