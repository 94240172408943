export const CONTACT_DATA = [
  {
    label: `“信息化背景下的家庭教育研究”课题组`,
    content: [
      {
        label: `地   址`,
        value: '北京市海淀区东北旺西路8号中关村软件园8号楼二层231A'
      },
      {
        label: '邮   编',
        value: '100085'
      },
      {
        label: '邮   箱',
        value: 'ketizu@1d1d100.com'
      },
      {
        label: '联系人',
        value: '贺老师 / 侯老师'
      },
      {
        label: '电   话',
        value: '18611908446 / 15010005020'
      }
    ]
  },
  {
    label: `中国教育发展战略学会教师发展专业委员会秘书处`,
    content: [
      {
        label: '地   址',
        value: '北京市海淀区北三环中路44号海淀文教产业园6号楼106室'
      },
      {
        label: '邮   编',
        value: '100088'
      },
      {
        label: '邮   箱',
        value: 'csedst@126.com'
      },
      {
        label: '联系人',
        value: '姜伟'
      },
      {
        label: '电   话',
        value: '010－57109575 / 13701178829 / 15510005587'
      }
    ]
  }
];
