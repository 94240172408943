import React from 'react';
import tabBg4 from 'imgs/1d1d/pc/ketizu/about_tab_bg4.png';
import styled from 'styled-components';
import { BgWrap } from '../__styled';

const ContentWrap = styled.div`
  font-size: 0.9rem;
  line-height: 1.6rem;
  text-align: justify;
  & > p {
    color: #565656;
    margin-bottom: 1.8rem;
  }
`;

export default function TabItem4({ id }) {
  return (
    <div className="tab-content-item" id={id} data-index="3">
      <BgWrap bg={tabBg4} />
      <div className="info-box">
        <h1 className="info-box-title">学会介绍 </h1>
        <ContentWrap>
          <p>
            中国教育发展战略学会是为适应教育事业发展与改革的需要，于2005年经教育部、民政部批准成立的国家一级学会。主管单位是中华人民共和国教育部。中国教育发展战略学会是由从事教育战略、规划研究和教育决策、管理的相关机构和人员自愿组成的群众性学术团体，是非营利性的社会组织。
          </p>
          <p>
            为贯彻落实《中共中央国务院关于全面深化新时代教师队伍建设改革的意见》，在中国教育发展战略学会的指导下，在众多从事教师队伍建设领域领导、专家、学者的大力支持下，中国教育发展战略学会批准设立教师发展专业委员会（以下简称“专委会”）。专委会是由从事教师队伍建设理论研究与实践探索的高校、中小学、幼儿园、教育行政部门业务以及支持教师队伍建设事业发展的企事业单位和个人自愿组成的全国性、学术性社会组织。
          </p>
          <p>
            专委会的主要业务范围有：理论研究、学术交流、专业培训、展览展示、国际合作、行业监测、咨询服务和书刊编辑。专委会坚持学术立会，充分发挥学会人才荟萃、智力密集、联系广泛的优势，系统开展有针对性、富有成效的交流、对接和服务工作，大力提升教师队伍建设的科学性和实效性。
          </p>
          <p>
            专委会以传播教师队伍建设方针政策、培养造就一支“有理想信念、有道德情操、有扎实知识、有仁爱之心”的大国良师队为宗旨，以习近平新时代中国特色社会主义思想为指导，坚持以人民为中心的发展思想，坚持全面深化改革，牢固树立新发展理念，全面贯彻党的教育方针，坚持社会主义办学方向，落实立德树人根本任务，遵循教育规律和教师成长发展规律，加强师德师风建设，培养高素质教师队伍，倡导全社会尊师重教，形成优秀人才争相从教、教师人人尽展其才、好教师不断涌现的良好局面。
          </p>
          <p>
            专委会致力于将社会发展对教师队伍建设的总体要求和教育事业发展的需求作为教师队伍建设工作的出发点和落脚点，注重解决教师队伍建设工作中遇到的各类问题。专委会致力于为政府、教育行政、业务部门及学校搭建相互交流的平台，积极开展教师队伍建设理论研究与实践探索，构建和完善新时代教师队伍建设理论研究与实践操作体系。
          </p>
        </ContentWrap>
        <a className="info-box-btn" href="https://www.wjx.cn/jq/102693804.aspx">
          申报课题实验基地
        </a>
      </div>
    </div>
  );
}
