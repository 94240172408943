/* eslint-disable no-irregular-whitespace */
import React from 'react';
import styled from 'styled-components';
import Container from '../__Container';
import Banner from '../__Banner';
import BannerImg from 'imgs/1d1d/pc/banner.contact.png';

import MapImg from 'imgs/1d1d/pc/contact.map.png';
import LocImg from 'imgs/1d1d/pc/contact.loc.jpg';

const Map = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  padding: 4rem 0;
  > img {
    width: 54.2rem;
    margin-bottom: -9.6rem;
  }
`;
const Loc = styled.section`
  display: flex;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 9rem;
  padding: 2rem 0 9.6rem 0;
  .info {
    > h2 {
      color: #2c2c2c;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      font-weight: bold;
    }
    p {
      display: flex;
      line-height: 1.8;
      > span {
        font-size: 0.8rem;
        color: #636363;
        &:first-child {
          margin-right: 0.4rem;
        }
      }
    }
    > img {
      width: 25.7rem;
    }
  }
`;

export default function Contact() {
  return (
    <Container>
      <Banner banner={BannerImg} h1="联系我们" h2s={['contact us']} />
      <Map>
        <img data-aos="fade-up" src={MapImg} alt="中国地图" />
      </Map>
      <Loc>
        <div className="info" data-aos="fade-right">
          <h2>壹点壹滴总部联系方式</h2>
          {/* <p>
            <span>人员招聘:</span>
            <span>hr@1d1d100.com</span>
          </p>
          <p>
            // <span>热线电话:</span>
            // <span>400-666-7689</span>
          </p> */}
          <p>
            <span>邮    箱:</span>
            <span>scb@1d1d100.com</span>
          </p>
          <p>
            <span>地    址:</span>
            <span>北京市海淀区农大南路1号院2A号楼</span>
          </p>
          {/* <p>
            <span>邮    编:</span>
            <span>100008</span>
          </p> */}
        </div>
        <img data-aos="fade-left" src={LocImg} alt="公司地址配图" />
      </Loc>
    </Container>
  );
}
