import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
  width: 100%;
  position: absolute;
  top: 1.45rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      background: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      transform: translateZ(0);
      top: 0;
      height: 4rem;
      .header {
        height: 100%;
        a {
          color: #000 !important;
        }
        .l-box li .active::after {
          top: 2.35rem !important;
        }
        .join {
          color: rgba(229, 1, 19, 1) !important;
          border: 0.07rem solid rgba(229, 1, 19, 1) !important;
        }
      }
    `}
  .header {
    width: 1200px;
    margin: 0 auto;
    font-size: 0.9rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: rgba(255, 255, 255, 1);
    }
    & > img {
      width: 2.85rem;
      height: 2.85rem;
    }
    .l-box {
      display: flex;
      align-items: center;
      li {
        display: inline-block;
        .active {
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 0.3rem;
            background: rgba(229, 1, 19, 1);
            position: absolute;
            top: 1.7rem;
          }
        }
        &:nth-child(1) {
          margin-right: 3.95rem;
        }
        &:nth-child(2) {
          margin-right: 3.9rem;
        }
        &:nth-child(3) {
          margin-right: 1.7rem;
        }
      }
      .join {
        box-sizing: border-box;
        border: 1px solid #ffffff;
        border-radius: 4px;
        position: relative;
        padding: 0;
        line-height: 1.9rem;
        color: rgba(255, 255, 255, 1);
        width: 5.94rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
