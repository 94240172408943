import React from 'react';
import bg from 'imgs/1d1d/pc/ketizu/home_bg@2x.jpg';
import Layout from '../../components/keti/layout';
import Banner from './__banner';
import About from './__about';
import ResearchBase from './__researchBase';

export default function Ketizu() {
  return (
    <Layout>
      <Banner banner={bg} />
      <About />
      <ResearchBase />
    </Layout>
  );
}
