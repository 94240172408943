const Videos = [
  {
    video: 'https://img001.1d1d100.com/Zijing/2021newyear.mp4',
    title: '壹点壹滴创始人王红兵2021新春致辞',
    cover:
      'https://img001.1d1d100.com/Zijing/2021newyear.mp4?x-oss-process=video/snapshot,t_5000,f_jpg,w_800,h_316'
  },
  {
    title: 'YES！WE CAN！',
    cover: 'https://file.1d1d100.com/2020/7/3/3599a8c8afdb42eda5c32bfd68fe456b.jpg',
    video: 'https://img001.1d1d100.com/Zijing/lcan.mp4'
  },
  {
    title: '壹点壹滴MV',
    cover: 'https://file.1d1d100.com/2019/7/24/a0fa59dfdf874bb0966dfc1fe9328c5f.png',
    video: 'https://img001.1d1d100.com/Zijing/childrens_day_blessing.mp4'
  },
  {
    title: '重温点滴，燃爆幼教',
    cover: 'https://file.1d1d100.com/2019/7/24/39a1d14c96b6463c806d25bf39421e88.png',
    video: 'https://img001.1d1d100.com/Zijing/rewarming_blasting_preschool.mp4'
  },
  {
    video: 'https://img001.1d1d100.com/Zijing/childrens_day_blessing.mp4',
    title: '壹点壹滴董事长王红兵携全体员工儿童节送祝福',
    cover: 'https://file.1d1d100.com/2019/7/24/6ed1075f043e43e983efc3a3670aabcd.png'
  },
  // 暂时注释
  // {
  //   video: 'https://img001.1d1d100.com/Zijing/into_zijing.mp4',
  //   title: '走进紫荆儿童成长云平台',
  //   cover: 'https://file.1d1d100.com/2019/7/24/50aa7c21387f4c44b97db877952bed35.png'
  // },
  {
    video: 'https://img001.1d1d100.com/Zijing/紫荆家长学校郭老致辞.mp4',
    title: '紫荆家庭教育总督导郭福昌开学致辞',
    cover: 'https://file.1d1d100.com/2019/9/6/46f5f7effc6d4e1fa6df07b9acec75c3.png'
  },
  {
    video: 'https://img001.1d1d100.com/Zijing/不忘初心铸爱成长.mp4',
    title: '不忘初心,铸爱成长',
    cover: 'https://file.1d1d100.com/2019/9/11/1c2a604856574db1a218f23f40536cd3.jpeg'
  }
];
export default Videos;
