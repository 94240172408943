import React from 'react';
import styled from 'styled-components';
import PicHands from 'imgs/1d1d/pc/partner.condition.png';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.6rem 0 5.4rem 0;
  .heads {
    display: flex;
    justify-content: space-between;
    width: 66rem;
    margin-bottom: 3.6rem;
    > h2 {
      color: #ff7c34;
      font-size: 2.6rem;
      width: 20rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    > h3 {
      color: #2c2c2c;
      font-size: 2.4rem;
    }
  }
  .info {
    display: flex;
    align-items: flex-start;

    > img {
      width: 36.1rem;
    }
    .txt {
      display: flex;
      flex-direction: column;
      margin-left: 5.4rem;
      .five {
        /* display: flex;
        flex-direction: column; */
        > p {
          color: #636363;
          font-size: 0.7rem;
          .num {
            position: relative;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            line-height: 1.4rem;
            color: #ff7c34;
            width: 1.4rem;
            height: 1.4rem;
            border: 1px solid #ff7c34;
            border-radius: 50%;
            margin-right: 0.5rem;
          }

          &:not(:last-child) {
            margin-bottom: 1.9rem;
            .num:after {
              position: absolute;
              content: '';
              left: 50%;
              transform: translateX(-50%);
              bottom: -1.5rem;
              display: block;
              width: 1px;
              height: 1rem;
              background: #ff7c34;
            }
          }
        }
      }
      .sayno {
        h3 {
          color: #2c2c2c;
          font-size: 1.3rem;
          margin-top: 2.2rem;
          margin-bottom: 1.4rem;
        }
        > p {
          color: #636363;
          font-size: 0.7rem;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
`;
const Conditions = [
  '热爱幼教，热爱孩子；',
  '做人诚实，做事规范，遇事包容，崇尚共赢；',
  '有团队，能打仗；',
  '曾服务过100家以上幼儿园；',
  '区域幼教集团投资人、总园长优先；区域民办幼教协会重大贡献者优先。'
];

export default function Features() {
  return (
    <Wrapper>
      <div className="heads">
        <h2 data-aos="fade-left">recruitment conditions</h2>
        <h3 data-aos="fade-right">地级市合伙人招募条件</h3>
      </div>
      <div className="info">
        <img data-aos="flip-up" src={PicHands} alt="条件配图" />
        <div className="txt">
          <div className="five">
            {Conditions.map((c, idx) => {
              return (
                <p key={c}>
                  <span className="num">{idx + 1}</span>
                  <span>{c}</span>
                </p>
              );
            })}
          </div>
          <div className="sayno">
            <h3>三类合伙人我们坚决不要</h3>
            <p>第一，没有团队，或者不会带团队的；</p>
            <p>第二，懂商业但不懂教育的；</p>
            <p>第三，只求利己不求利他的。</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
