import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.4rem 0 4rem 0;
  > h2 {
    color: #ff7c34;
    text-transform: uppercase;
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  > h3 {
    color: #2c2c2c;
    font-size: 1.9rem;
    margin-bottom: 2.9rem;
  }
`;
export default function Banner({ title, subTitle, img, imgW }) {
  return (
    <Wrapper>
      <h2 data-aos="fade-right">{title}</h2>
      <h3 data-aos="fade-left">{subTitle}</h3>
      <img data-aos="fade-up" src={img} alt="Banner" style={{ width: imgW }} />
    </Wrapper>
  );
}
