import React from 'react';
import GlobalStyle from 'RootCommon/Global.style';
import Nav from '../__Nav';
import Footer from '../__Footer';
import Banner from '../__Banner';
import IntroImg from 'imgs/1d1d/pc/banner.intro.png';

export default function About() {
  return (
    <>
      <GlobalStyle />
      <Nav />
      <Banner banner={IntroImg} />
      关于壹点壹滴
      <Footer />
    </>
  );
}
