import React from 'react';
import { Helmet } from 'react-helmet';

import Agreement from 'RootCommon/zj_user_agreement';
const UserAgreement = () => {
  return (
    <div>
      <Helmet title="用户服务协议" />
      <Agreement />
    </div>
  );
};

export default UserAgreement;
