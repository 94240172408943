import React from 'react';
// import styled from 'styled-components';
import Container from '../__Container';

import Banner from '../__Banner';
import Steps from './_Steps';
import Requirement from './_Requirement';
import Companion from './_Companion';
import BannerImg from 'imgs/1d1d/pc/banner.join.png';

export default function Join() {
  return (
    <Container>
      <Banner banner={BannerImg} h1="如何加入" h2s={['how to join us']} />
      <Steps />
      <Requirement />
      <Companion />
    </Container>
  );
}
