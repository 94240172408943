import React from 'react';
import styled from 'styled-components';
import PicChart from 'imgs/1d1d/pc/partner.chart.png';
import QRImg from 'imgs/1d1d/pc/partner.qr.png';
// import ZJImg from 'imgs/1d1d/pc/partner.zj.png';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.6rem 0 5.4rem 0;
  background: #fafafa;
  .heads {
    display: flex;
    justify-content: space-between;
    width: 66rem;
    > h2 {
      color: #ff7c34;
      font-size: 2.6rem;
      width: 20rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    > h3 {
      color: #2c2c2c;
      font-size: 2.4rem;
    }
  }
  .info {
    display: flex;
    align-items: center;
    width: 66rem;
    > img {
      width: 14.5rem;
    }
    .txt {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 27.7rem;
      height: 11.2rem;
      margin-right: 17.6rem;
      position: relative;
      > img {
        position: absolute;
        bottom: 0;
        right: 6rem;
        width: 5.3rem;
        &:last-child {
          right: 0;
          height: 5.3rem;
        }
      }
      > p {
        font-size: 0.7rem;
        line-height: 1.6;
        &.tel {
          font-size: 0.9rem;
          font-weight: bold;
        }
      }
    }
  }
`;

export default function BePartner() {
  return (
    <Wrapper>
      <div className="heads">
        <h2 data-aos="fade-left">Regional partner</h2>
        <h3 data-aos="fade-right">立即成为区域合伙人</h3>
      </div>
      <div className="info">
        <div className="txt">
          <p>
            {/* 暂时注释 */}
            {/**和紫荆儿童成长云平台 */}
            在我们眼里，区域合伙人是兄弟，是姐妹；是壹点壹滴在各地级市的首席代表，
            是未来中国幼教互联网区域入口的服务者、价值贡献者。
          </p>
          <p>
            {/**，认可紫荆儿童成长云平台 */}
            如果你认可壹点壹滴，请速扫描下方二维码报名，成为我们的合伙人！
          </p>
          <p>给我一个机会，还你一个惊喜！</p>
          <p className="tel">咨询热线 : 400-666-7689</p>
          <img src={QRImg} alt="二维码" />
          {/* <img src={ZJImg} alt="二维码" /> */}
        </div>
        <img src={PicChart} data-aos="zoom-in" alt="成为合伙人配图" />
      </div>
    </Wrapper>
  );
}
