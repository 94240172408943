import ImgVision from 'imgs/1d1d/pc/culture.vision.png';
import ImgMission from 'imgs/1d1d/pc/culture.mission.png';
import ImgValue from 'imgs/1d1d/pc/culture.value.png';
import ImgTime from 'imgs/1d1d/pc/culture.time.png';
import ImgSlogan from 'imgs/1d1d/pc/culture.slogan.png';

const Sections = [
  {
    title: '愿景',
    subTitle: '做推动行业进步的幼教互联网企业',
    img: ImgVision,
    imgW: '19.2rem'
  },
  {
    title: '使命',
    subTitle: '以技术引领幼教行业全面升级  用爱和责任推动幼儿教育公平',
    img: ImgMission,
    imgW: '17.8rem'
  },
  {
    title: '核心价值观',
    subTitle: '以客户为中心  以奋斗者为本    专注儿童成长  追求卓越品质',
    img: ImgValue,
    imgW: '15.7rem'
  },
  {
    title: '团队文化',
    subTitle: '全情投入  极致执行  数字说话  使命必达',
    img: ImgTime,
    imgW: '17.8rem'
  },
  {
    title: '口号',
    subTitle: '全力以赴，跑赢中国幼教新十年！   全力以赴，跑赢中国幼教新未来！',
    img: ImgSlogan,
    imgW: '19.1rem'
  }
];
export default Sections;
