import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import IconTriangle from 'imgs/1d1d/pc/icon.triangle.png';
import IconMobile from 'imgs/1d1d/pc/icon.mobile.png';
import links from 'RootCommon/links';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.6rem 0 8rem 0;
  > h2 {
    font-family: 'ITCAvantGardeStd-XLt';
    color: #ff7c34;
    font-size: 3.3rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1.9rem;
  }
  > h3 {
    color: #2c2c2c;
    font-size: 2.4rem;
    margin-bottom: 5.8rem;
  }
  .step {
    width: 65.3rem;
    box-shadow: 0px 0px 21px 0px rgba(66, 0, 87, 0.11);
    border-radius: 10px;
    padding: 2.8rem 0 1.3rem 0;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.noicon {
      padding-bottom: 4rem;
    }
    .title {
      color: #2c2c2c;
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
    .desc {
      font-size: 1.1rem;
      > p {
        text-align: center;
        color: #787878;
        margin-bottom: 0.8rem;
      }
    }
    .subDesc {
      font-size: 0.8rem;
      margin-top: 1.2rem;
      > p {
        text-align: center;
        color: #212121;
        margin-bottom: 0.6rem;
      }
    }
    .mobile {
      margin-top: 1.7rem;
      width: 15rem;
      height: 2rem;
      padding-left: 1.4rem;
      background: url(${IconMobile});
      background-repeat: no-repeat;
      background-size: 0.7rem;
      background-position-x: 0.3rem;
      background-position-y: center;
      font-size: 0.8rem;
    }
    .btn {
      margin-top: 1.7rem;
      padding: 0.7rem 1.9rem;
      background: rgb(255, 124, 52);
      box-shadow: 0px 5px 6px 0px rgba(56, 56, 56, 0.14);
      border-radius: 3px;
      color: #fff;
      font-size: 1rem;
    }
    .icon {
      margin-top: 1.1rem;
      width: 0.8rem;
    }
  }
`;
export default function Banner() {
  const [mobile, setMobile] = useState('');
  const handleMobileChange = (evt) => {
    setMobile(evt.target.value);
  };
  const handleApplyClick = (evt) => {
    if (!mobile) {
      alert('请输入手机号');
      evt.preventDefault();
    } else if (mobile.length !== 11) {
      alert('请输入正确的手机号');
      evt.preventDefault();
    }
  };
  return (
    <Wrapper>
      <h2>Open wonderful</h2>
      <h3>简单三步开启精彩</h3>
      <div data-aos="fade-down" className="step">
        <div className="title">申请使用</div>
        <div className="desc">
          <p>立即申请新幼教云平台，申请后我们会第一时间与您联系</p>
        </div>
        <input
          maxLength={11}
          value={mobile}
          onChange={handleMobileChange}
          type="number"
          placeholder="请输入您的手机号"
          className="mobile"
        />
        <Link to={`/apply/?mobile=${mobile}`} onClick={handleApplyClick} className="btn">
          立即申请
        </Link>
        <img src={IconTriangle} alt="引导三角" className="icon" />
      </div>
      <div data-aos="fade-down" className="step">
        <div className="title">平台开通</div>
        <div className="desc">
          <p>申请成功并支付平台服务费后，您的专属区域服务督导会及时与您联络，</p>
          <p>为您办理壹点壹滴“幼教互联网”平台开通事宜</p>
        </div>
        <a rel="noopener noreferrer" target="_blank" href={links.onlineChat} className="btn">
          在线咨询
        </a>
        <img src={IconTriangle} alt="引导三角" className="icon" />
      </div>
      <div data-aos="fade-down" className="step noicon">
        <div className="title">进入平台开始使用</div>
        <div className="desc">
          <p>用专业的互联网技术和内容真正为您的幼儿园赋能</p>
        </div>
        <div className="subDesc">
          <p>专业技术支持帮您顺利接入新幼教云平台</p>
          <p>平台顾问帮您解决平台开通后的问题</p>
          <p>区域督导帮助您在招生、培训、管理和家园共育等方面完成升级</p>
        </div>
        <a href={links.headmaster} rel="noopener noreferrer" target="_blank" className="btn">
          登录平台
        </a>
      </div>
    </Wrapper>
  );
}
