import React from 'react';

const Wrapper = () => {
  return (
    <div>
      <div>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'center',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt', fontWeight: 'bold' }}>
            儿童个人信息保护政策
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            北京点点未来科技发展有限公司（以下简称“点点未来”）的“新幼教云平台“，简称“幼教云”，
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            一直非常注重对未成年人的保护，致力于践行我们的企业社会责任。本《儿童个人信息保护政策》（以下也称“本政策”）旨在更详细的向监护人（以下也称“您”）和儿童说明我们如何收集、使用、存储和处理儿童个人信息，以及我们为您与儿童提供的访问、更正、删除、保护这些信息的方式。如果您是儿童用户的监护人，请您仔细阅读、充分理解并决定选择是否同意本政策以及是否同意您和您监护的儿童使用我们的产品与/或服务。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>一. 适用范围</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            本《儿童个人信息保护政策》（以下也称“本政策”）仅适用于
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            幼教云平台中涉及到的儿童个人信息，或者幼教云中
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            面向儿童群体并为儿童提供专属内容的
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            哄宝神器等公众号/小程序内容
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>（本政策中也称“儿童</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>专属内容</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>”）。《</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>隐私</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>》展示了</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            收集、使用、处理、保护个人信息的一般做法，本政策为《
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户隐私协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            》的一部分；如在儿童个人信息保护政策方面，本政策与《
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>隐私</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            》正文存在不一致的，本政策优先适用，如本政策中未提及的，则视产品实际情况适用/参照适用《
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>隐私</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>》正文执行或以</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>点点未来</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            另行说明为准。除非本政策另有说明，本政策中涉及的词语含义与《
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>隐私</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>》正文中的一致。</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            儿童：指不满十四周岁的未成年人。（出自于《儿童个人信息网络保护规定》）
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            二. 我们收集和使用儿童个人信息的规则
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们会严格履行法律规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则收集和使用儿童个人信息，不会收集与我们提供的产品与/或服务无关的儿童个人信息，不会违反法律规定和我们与您的约定收集、使用儿童个人信息。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们收集和使用的儿童个人信息类型包括两种：第一种：
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            的核心业务功能所必需的信息：此类信息为
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            正常运行的必备信息，您须授权我们收集。如您拒绝提供，您和儿童将无法正常使用我们的产品与/或服务；第二种：
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            的附加业务功能可能需要收集的信息：此信息为非核心业务功能所需的信息，您可以选择是否授权我们收集。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的服务效果，但不影响您和儿童对核心业务功能的正常使用。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>具体业务功能场景包括：</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>在儿童使用</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>的过程中，我们会收集</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            儿童的真实姓名、性别、生日、肖像、家庭住址、联系方式、监护人信息、兴趣爱好、家庭情况、受教育情况等
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>，我们收集这些信息是</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            幼教云系统为儿童所在的学校和儿童及监护人之间建立联系所必要的信息，是儿童所在的学校在日常教育教学过程中所需要正常采集的信息，是
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            为了向您披露您监护的儿童的日常
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>行为</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>情况，同时</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>也更好的</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>为儿童</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>及其监护人</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>提供其需要的内容</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            和在学校期间所必要的资讯，以便更好地进行家园共育。
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>我们在此善意的建议您，</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>除这些必要的基本信息外</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>，请不要提供儿童的</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>更多的</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            信息，也不要轻易与他人共享儿童个人信息或将其公开发布。
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            在一些儿童专属内容上，比如哄宝神器，幼教云可能还会采集
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>上网操作记录（包括</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>音</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            视频观看记录、搜索记录、下载记录、收藏记录、点击记录）
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>，</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们手机这些信息是为了向您披露
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>您监护的儿童的日常</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>行为</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>情况，同时</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>也更好的</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>为儿童</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>及其监护人</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>提供</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>更精准的专属内容</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            和可能更感兴趣的服务，同时改进我们的产品和服务。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            此外，我们可能会收集和使用的儿童的其他个人信息的规则请您查阅《
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户隐私协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>》正文第二章节，</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            该内容将视实际情况适用/参照适用。因业务需要，当确需超出前述约定的目的、范围收集、使用您监护的儿童的个人信息的，我们会再次征得您的同意。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            三. 我们共享、转让、公开披露儿童个人信息的规则
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>(一) 共享</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            对于儿童个人信息，我们仅在本政策所述的目的和范围内或依照法律要求进行共享，除非存在以下一种或多种情形：
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            与作为监护人的您之间的共享；
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            事先已征得您的明确授权同意；
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            为了必要/合理的业务的顺利开展、满足您和儿童的要求、履行我们在相关用户协议或本政策中的义务和行使我们的权利或遵守法律规定等目的，与为我们的
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            提供功能支持的服务提供商的必要共享，但我们承诺我们只会共享儿童必要个人信息，如果我们需要改变儿童个人信息的处理目的时，将再次征求您的授权同意。请您放心，我们会对服务商和其服务行为等进行安全评估，签署协议，明确责任、处理事项、处理期限、处理性质和目的等，要求其处理行为不得超出我们的授权范围；
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            有权机关的要求、法律法规等规定的其他情形。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>(二) 转让与公开披露</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们会严格履行法律规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则转让、公开披露儿童个人信息。如您希望了解更多，请您查阅《
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>隐私</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>》</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>正文第四章节。</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            四. 您及/或儿童对儿童个人信息的控制权
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>我们在</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            中为您和儿童提供了多种对儿童个人信息行使控制权的功能，您可以通过这些功能管理儿童个人信息和儿童的网络生活，包括：
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>(一) 访问权</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>您或儿童可以在</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            中查询和访问儿童的相关个人信息，例如：个人资料信息、搜索记录、观看历史、收藏记录。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>(二) 更正/修改权</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>您可以在</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            中更正/修改儿童的相关个人信息，例如：个人资料信息；或您可以联系我们解决，我们会在经对您的身份进行验证，且不影响信息的客观性和准确性的情况下进行更正/修改。但出于安全性和身份识别的考虑，您可能无法修改注册时提交的某些初始注册信息。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            同时，如您或儿童发现我们收集、存储、使用、披露的儿童个人信息有错误的，可以联系我们更正，我们会在完成身份验证和核实问题后及时采取措施予以更正。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>(三) 删除权</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>您和儿童可以在</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            中自行删除儿童的相关个人信息，例如：
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>注销幼教云账号</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            。但请注意，若您和儿童自行删除或请求我们删除特定的儿童个人信息（如注册账号），可能导致无法继续使用我们的全部或部分产品与/或服务。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            在以下情形下，您或儿童可以直接向我们提出删除儿童个人信息的请求，我们会在完成身份验证和核实问题后及时采取措施予以删除，包括：
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们违反法律法规规定或我们与您的约定收集、存储、使用、转移、披露儿童个人信息的；
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息的；
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>您撤回同意的；</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            您或儿童通过注销等方式终止使用我们的
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>的；</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>法律法规等规定的其他情形。</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            (四) 提前获知产品与/或服务停止运营权
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们将持续为您提供优质服务，若因特殊原因导致我们的部分或全部产品与/或服务被迫停止运营，我们将提前在显著位置或向您发送推送消息或以其他方式通知您，并将停止对儿童个人信息的收集，删除或匿名化处理我们持有的儿童个人信息。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            五. 我们存储和保护儿童个人信息的规则
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们依照法律法规的规定，将儿童个人信息存储于中华人民共和国境内。我们仅在为提供我们的产品和服务之目的所必需且最短的期间内保留儿童个人信息。在超出上述存储期限后，我们会对儿童个人信息进行删除或匿名化处理。
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施来保护儿童个人信息；同时，我们遵循最小授权原则，对工作人员设定了严格的信息访问权限，控制儿童个人信息知悉范围；我们也采取了必要技术措施，避免违法复制、下载儿童个人信息。如您希望了解更多，请您查阅《
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>幼教云用户</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>隐私</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>协议</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>》</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>正文第六章节。</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>六. 如何联系我们</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            如您对本《儿童个人信息保护政策》的执行或使用我们的服务时遇到的与隐私保护相关的事宜有任何问题（包括问题咨询、投诉等），我们专门为您提供了多种反馈通道，希望为您提供满意的解决方案：
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            在线客服/其他在线意见反馈通道：您可与我们平台上产品功能页面的在线客服联系或者在线提交意见反馈；
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            人工客服通道：您可以拨打400-
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>666</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>-</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>7689</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>与</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>点点未来</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>联系；</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            专设的邮件通道：为更好地保护您的权益，我们专门设立了
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>info</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>@</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>1d1d100</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            .com邮箱，您可以通过该邮箱与我们联系；
          </span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            儿童个人信息保护： 您可发送邮件至
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>info</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>@</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>1d1d100</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            .com或写信至以下地址来与其联系：北京市
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            海淀区农大南路1号院2A号楼六层
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}> </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>行政与法务</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            部 儿童个人信息保护专员（收）；邮编：100
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>084</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>。</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>七. 其他</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            （一）本《儿童个人信息保护政策》的解释及争议解决均应适用中华人民共和国大陆地区法律。与本《儿童个人信息保护政策》相关的任何纠纷，双方应协商友好解决；若不能协商解决，您同意将争议提交至北京市
          </span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>海淀</span>
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>区人民法院诉讼解决。</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>&nbsp;</span>
        </p>
        <p
          style={{
            fontSize: '10.5pt',
            lineHeight: '115%',
            margin: '0pt 0pt 10pt',
            orphans: 0,
            textAlign: 'justify',
            widows: 0
          }}
        >
          <span style={{ fontFamily: '等线', fontSize: '10.5pt' }}>
            （二）本《儿童个人信息保护政策》的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。
          </span>
        </p>
      </div>
      <div className="cnzz" style={{ display: 'none' }}></div>
    </div>
  );
};

export default Wrapper;
