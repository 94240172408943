import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 0 8rem 0;
  background: rgb(249, 249, 249);
  > h2 {
    font-family: 'ITCAvantGardeStd-XLt';
    color: #ff7c34;
    font-size: 3.3rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 1.9rem;
  }
  > h3 {
    color: #2c2c2c;
    font-size: 2.4rem;
    margin-bottom: 5.8rem;
  }
  .desc {
    font-size: 1.1rem;
    > p {
      text-align: center;
      color: #787878;
      margin-bottom: 0.8rem;
    }
  }
  .btn {
    margin-top: 1.7rem;
    padding: 0.7rem 1.9rem;
    background: rgb(255, 124, 52);
    box-shadow: 0px 5px 6px 0px rgba(56, 56, 56, 0.14);
    border-radius: 3px;
    color: #fff;
    font-size: 1rem;
  }
`;
export default function Requirement() {
  return (
    <Wrapper>
      <h2>Opening requirement</h2>
      <h3>平台开通要求</h3>
      <div className="desc">
        <p>您只须向腾讯公司申请一个公众号，把它交给壹点壹滴，壹点壹滴5分钟</p>
        <p>就可赋能这个公众号，让它成为幼儿园服务平台。</p>
      </div>
      <a
        href="https://mp.weixin.qq.com/cgi-bin/registermidpage?action=index&lang=zh_CN"
        className="btn"
        rel="noopener noreferrer"
        target="_blank"
      >
        立即申请幼儿园微信公众号
      </a>
    </Wrapper>
  );
}
