import React from 'react';
import styled from 'styled-components';
import BannerImg from 'imgs/1d1d/pc/banner.service.png';
const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
  }
  > hgroup {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    h1 {
      color: #fff;
      font-size: 2.4rem;
      margin-top: 1rem;
    }
    h2 {
      font-weight: 800;
      color: #fff;
      font-size: 3rem;
      text-transform: uppercase;
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1.8;
      > p {
        color: #fff;
        font-size: 0.8rem;

        &:first-child {
          margin-top: 2rem;
        }
      }
    }
  }
`;
export default function Banner({
  banner = BannerImg,
  h1 = '',
  h2s = [],
  desc = [],
  h2ani = 'fade-down',
  h1ani = 'fade-up'
}) {
  return (
    <Wrapper>
      <img src={banner} alt="Banner" />
      {h1 && (
        <hgroup>
          {h2s.map(h2 => {
            return (
              <h2 data-aos={h2ani} key={h2}>
                {h2}
              </h2>
            );
          })}
          <h1 data-aos={h1ani}>{h1}</h1>
          <section className="desc">
            {desc.map((d, idx) => {
              return (
                <p data-aos="fade-left" data-aos-delay={(idx + 1) * 300} key={d}>
                  {d}
                </p>
              );
            })}
          </section>
        </hgroup>
      )}
    </Wrapper>
  );
}
