import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Cards from './_data';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.6rem 0 5.4rem 0;
  > h2 {
    color: #2c2c2c;
    font-size: 1.8rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 2.9rem;
      height: 0.2rem;
      background: #ff7c34;
      left: 50%;
      transform: translateX(-50%);
      bottom: -2rem;
    }
  }

  .cards {
    display: flex;
    width: 76rem;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    padding: 5.8rem 0 3.4rem 0;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid rgba(212, 212, 212, 0.79);
      border-radius: 10px;
      padding: 2.2rem 0.7rem 1rem 0.7rem;
      width: 22rem;
      min-height: 19.2rem;
      margin-right: 2rem;
      margin-bottom: 1.8rem;
      border-radius: 10px;
      &:hover {
        border: 1px solid #fff;
        box-shadow: 0px 0px 20px 7px rgba(91, 91, 91, 0.08);
      }
      &.noRightM {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .icon {
        width: 5rem;
        margin-bottom: 1.4rem;
      }
      > h3 {
        font-size: 0.9rem;
        color: #2c2c2c;
        font-weight: bold;
        margin-bottom: 0.6rem;
      }
      > h4 {
        font-size: 0.7rem;
        color: #2c2c2c;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }
      > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
          color: #787878;
          font-size: 0.7rem;
          line-height: 2;
          text-align: center;
          &:before {
            content: '★';
            display: inline-block;
            font-size: 0.5rem;
            vertical-align: middle;
          }
        }
        .noStar {
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .btn {
    background: rgb(255, 124, 52);
    box-shadow: 0px 6px 10px 0px rgba(172, 61, 0, 0.34);
    border-radius: 27px;
    padding: 1rem 2rem;
    color: #fff;
  }
`;
export default function Banner() {
  return (
    <Wrapper>
      <h2>为什么选择壹点壹滴幼教云？</h2>
      <div className="cards">
        {Cards.map((c, idx) => {
          const { title, subTitle, list, icon } = c;
          return (
            <div key={title} className={`card ${idx === 2 ? 'noRightM' : ''}`}>
              <img src={icon} alt="产品图标" className="icon" />
              <h3>{title}</h3>
              <h4>{subTitle}</h4>
              <ul>
                {list.map((l, i) => {
                  return (
                    <li key={i} className={l.flag ? 'noStar' : ''}>
                      {l.flag ? l.text : l}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <Link to="/apply" className="btn">
        立即体验幼教云
      </Link>
    </Wrapper>
  );
}
