import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import videos from 'RootCommon/_videoData';

global.$ = global.jQuery = require('jquery');
// const jQuery = require('jquery');
// import jQuery from 'jquery';
// import './_jq.3d';
// require('./_jq.3d');
const Wrapper = styled.section`
  padding: 4rem 3rem 8rem 3rem;
  .playModal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    > .video {
      width: 70vw;
      height: 60vh;
      > video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .closeBtn {
      display: block;
      width: 37px;
      height: 37px;
      background: url('http://www.100tal.com/skin/img/close1.png') no-repeat;
      right: 22px;
      top: 22px;
      position: absolute;
      z-index: 99;
      cursor: pointer;
    }
  }
  #gla_box {
    width: 40rem;
    margin: auto;
    position: relative;
    .gla_inbox {
      overflow: hidden;
      position: relative;
      p {
        text-indent: 1em;
        font-size: 0.7rem;
        width: 100%;
        color: #ffffff;
        line-height: 1.5;
        background: #000;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .roundabout-holder {
      height: 27rem;
      width: 100%;
    }
    .roundabout-moveable-item {
      display: block;
      height: 27rem;
      width: 800px;
      cursor: pointer;
    }
    .roundabout-in-focus {
      cursor: auto;
    }
    .box-con {
      position: relative;
      p {
        position: absolute;
        display: none;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 70px;
        line-height: 70px;
        font-size: 16px;
        color: #fff;
        text-indent: 1.5em;
        background: rgba(0, 0, 0, 0.8);
      }
      .ico {
        position: absolute;
        cursor: pointer;
        width: 104px;
        height: 104px;
        background: url('http://www.100tal.com/skin/img/aniu.png') no-repeat center;
        background-size: 104px;
        left: 50%;
        top: 50%;
        margin: -52px 0 0 -52px;
        display: none;
      }
      .boxbg {
        display: none;
        cursor: pointer;
        height: 540px;
        width: 800px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
      }
    }
    .roundabout-in-focus .box-con {
      p,
      .ico,
      .boxbg {
        display: block;
      }
    }
  }
`;
export default function Slider() {
  const [vUrl, setVUrl] = useState('');
  const videoEle = useRef(null);
  useEffect(() => {
    console.log('fsfsdf');
    const script = document.createElement('script');

    script.src = '/jq.3d.js';
    script.async = true;
    script.onload = () => {
      $('#gla_box>ul').roundabout({
        duration: 1000,
        reflect: true,
        autoplayDuration: 5000
      });
    };
    document.body.appendChild(script);
  }, []);
  const handleClick = (vUrl) => {
    setVUrl(vUrl);
  };
  useEffect(() => {
    videoEle.current && videoEle.current.play();
  }, [vUrl]);
  const handleCloseModal = () => {
    setVUrl('');
  };
  return (
    <Wrapper>
      {vUrl && (
        <div className="playModal">
          <div className="video">
            <video src={vUrl} ref={videoEle} controls />
          </div>
          <div className="closeBtn" onClick={handleCloseModal}></div>
        </div>
      )}
      <div id="gla_box">
        <ul>
          {videos.map((v, idx) => {
            return (
              <li key={v.title + idx}>
                <div className="gla_inbox">
                  <div className="box-con" onClick={handleClick.bind(null, v.video)}>
                    <div className="boxbg"></div>
                    <i className="ico" data-title={v.title}></i>
                    <img src={v.cover} />
                    <p>{v.title}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
}
