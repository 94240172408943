import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import icon1 from 'imgs/1d1d/pc/ketizu/about_icon1.png';
import icon2 from 'imgs/1d1d/pc/ketizu/about_icon2.png';
import icon3 from 'imgs/1d1d/pc/ketizu/about_icon3.png';
import icon4 from 'imgs/1d1d/pc/ketizu/about_icon4.png';
import TabItem1 from './__tabItem1';
import TabItem2 from './__tabItem2';
import TabItem3 from './__tabItem3';
import TabItem4 from './__tabItem4';

const Wrapper = styled.section`
  .fixed {
    position: fixed;
    top: 4rem;
    left: 0;
    right: 0;
    z-index: 6;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    .tab-item {
      height: 3rem !important;
      img {
        display: none !important;
      }
    }
  }
  .tab-placeholder {
    height: 3rem;
  }
  .tab-box {
    display: flex;
    justify-content: center;
    .tab-item {
      width: 15.85rem;
      height: 6.9rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ccc;
      position: relative;
      &:last-child {
        border: none;
      }

      & > img {
        width: 3rem;
        height: 3rem;
        display: block;
        margin-right: 1.2rem;
      }
    }
    .active::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.35rem;
      background: rgba(229, 1, 19, 1);
      position: absolute;
      bottom: 0;
    }
  }
  .tab-content {
    .tab-content-item {
      position: relative;
      padding: 84px 0;
      overflow: hidden;
      .info-box {
        background-color: rgba(255, 255, 255, 0.95);
        box-sizing: border-box;
        padding: 76px 26px 60px 48px;
        box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 1;
        margin: 0 auto;
        width: 66.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        &-title {
          font-size: 1.8rem;
          line-height: 1;
          color: #e50113;
          margin-bottom: 3.45rem;
        }
        &-btn {
          width: 13.3rem;
          height: 3.2rem;
          border: 1px solid rgba(229, 1, 19, 1);
          font-size: 0.9rem;
          color: #e50113;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }
`;

const TABDATA = [
  {
    title: '研究背景',
    icon: icon1
  },
  {
    title: '专家团队',
    icon: icon2
  },
  {
    title: '实验基地',
    icon: icon3
  },
  {
    title: '学会介绍',
    icon: icon4
  }
];

export default function TabBar() {
  const wrapRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHeader, setIsHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const { offsetTop } = wrapRef.current || { offsetTop: 0 }; // 内容盒子
      if (scrollTop >= offsetTop - 2) {
        setIsHeader(true);
      } else {
        setIsHeader(false);
      }
    };
    wrapRef.current && document.addEventListener('scroll', handleScroll, false);
    handleScroll();
    return () => {
      wrapRef.current = null;
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      function (entries) {
        entries.forEach((item) => {
          if (item.isIntersecting) {
            setActiveIndex(item.target.dataset.index * 1);
          }
        });
      },
      {
        root: null, // null 为相对于视窗，也可为具体要监测的某个祖先元素
        rootMargin: '1px' // 距离祖先元素的 margin
      }
    );
    let list = document.querySelectorAll('.tab-content-item');
    list.forEach((itm) => {
      intersectionObserver.observe(itm);
    });
    return () => {
      list.forEach((itm) => {
        intersectionObserver.unobserve(itm);
      });
      list = null;
    };
  }, []);

  return (
    <Wrapper ref={wrapRef}>
      <div className={`tab-box ${isHeader ? 'fixed' : ''}`}>
        {TABDATA.map(({ title, icon }, idx) => (
          <div
            className={`tab-item ${activeIndex === idx ? 'active' : ''}`}
            key={title}
            onClick={() => {
              const dom = document.querySelector(`#tab-${idx + 1}`);
              const offsetTop = dom.offsetTop + 2;
              window.scrollTo(0, offsetTop);
              setActiveIndex(idx);
            }}
          >
            <img src={icon} alt="" />
            <div>{title}</div>
          </div>
        ))}
      </div>
      <div className="tab-content">
        <TabItem1 id={`tab-1`} />
        <TabItem2 id={`tab-2`} />
        <TabItem3 id={`tab-3`} />
        <TabItem4 id={`tab-4`} />
      </div>
    </Wrapper>
  );
}
