import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Nav from '../pages/__Nav';
import Footer from '../pages/__Footer';
import GlobalStyle from '../pages/__Global.style';
const Wrapper = styled.article`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #636363;
  min-height: 80vh;
  width: 60rem;
  padding: 8rem 3.6rem 5.3rem 3.6rem;
  h1 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }
  time {
    font-size: 0.7rem;
    margin-bottom: 1.2rem;
  }
  .line {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: rgb(191, 191, 191);
    margin-bottom: 1.7rem;
  }
  .content {
    align-self: flex-start;
    padding: 0 0.7rem;
    line-height: 1.6;
    font-size: 0.7rem;
    width: 100%;
    > p {
      /* text-align: left; */
      text-indent: 2em;
      > img,
      > iframe {
        display: block;
        margin: 0 auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }
`;
function createMarkup(html) {
  return { __html: html };
}
const Ddarticle = ({ pageContext: { article } }) => {
  console.log('article', article);
  const { title, date, description, content } = article;
  return (
    <>
      <Helmet defer={false}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href="/favicon.1d1d.ico" />
      </Helmet>
      <GlobalStyle />
      <Nav />
      <Wrapper>
        <h1>{title}</h1>
        <time>{date}</time>
        <div className="line" />
        <section className="content" dangerouslySetInnerHTML={createMarkup(content)}></section>
      </Wrapper>
      <Footer />
    </>
  );
};
export default Ddarticle;
