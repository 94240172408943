import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
    z-index: -1;
    height: 480px;
    object-fit: cover;
  }
  .content {
    width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  .title-box {
    h1 {
      font-size: 2.4em;
      line-height: 1;
      color: #ffffff;
      margin-bottom: 1.5rem;
    }
    h2 {
      width: 22.75rem;
      font-size: 0.9rem;
      color: #ffffff;
      line-height: 1.4rem;
    }
  }
`;

export default function Banner({ banner }) {
  return (
    <Wrapper>
      <img src={banner} alt=" " />
      <div className="content">
        <div className="title-box">
          <h1>课程实验基地</h1>
          <h2>
            为认真贯彻落实习近平总书记关于家庭教育的重要讲话精神，根据教育部《关于加强家庭教育工作的指导意见》要求，经中国教育发展战略学会教师发展专业委员会专家评议、园所资质核查及综合评定，共有百余家幼儿园被评为“信息化背景下的家庭教育研究”实验基地。
          </h2>
        </div>
      </div>
    </Wrapper>
  );
}
