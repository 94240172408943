/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import IconWx from 'imgs/1d1d/pc/social.icon.wx.png';
import QrWx from 'imgs/1d1d/qr.wx.jpeg';
import IconTt from 'imgs/1d1d/pc/social.icon.tt.png';
import QrTt from 'imgs/1d1d/qr.toutiao.jpeg';
import IconDy from 'imgs/1d1d/pc/social.icon.dy.png';
import QrDouyin from 'imgs/1d1d/qr.douyin.jpeg';
import links from 'RootCommon/links';
const TipsShow = keyframes`
 0% {
        opacity: .2;
      }
      
      to {
        opacity: 1;
    }
`;
const Wrapper = styled.footer`
  background: rgb(234, 234, 234);

  .wrapper {
    max-width: 96rem;
    height: 6.5rem;
    padding: 0 4rem;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .left {
      display: flex;
      flex-direction: column;
      .links {
        display: flex;
        margin-bottom: 0.7rem;
        a:not(:last-child):after {
          content: '|';
          padding: 0 1rem;
        }
      }
      .copyright {
        font-size: 12px;
        text-transform: uppercase;
        > span:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
    .middle {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      line-height: 1.4;
    }
    .right {
      display: flex;
      .social {
        z-index: 9999;
        position: relative;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
        .qr {
          display: none;
          position: absolute;
          border: 1px solid #aaa;
          border-radius: 0.4rem;
          width: 6rem;
          left: 50%;
          top: -360%;
          transform: translateX(-50%);
          animation: ${TipsShow} 0.3s ease-in 1 forwards;
        }
        &:hover {
          .qr {
            display: block;
          }
        }
      }
    }
  }
`;
export default function Footer() {
  return (
    <Wrapper>
      <div className="wrapper">
        <div className="left">
          <div className="links">
            {/* 暂时注释 */}
            {/* <a href={links.zj} rel="noopener noreferrer" target="_blank">
              紫荆儿童成长云平台
            </a> */}
            <Link to="/founder/">创始人故事</Link>
            <Link to="/cooperation/">战略合作</Link>
            <Link to="/apply/">申请使用</Link>
            <a href={links.headmaster} rel="noopener noreferrer" target="_blank">
              园长入口
            </a>
          </div>
          <div className="copyright">
            <span>Copyright © All Rights Reserved</span>
            <span>
              <a
                rel="noopener noreferrer"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030803"
                target="_blank"
              >
                京公网安备 11010802030803号
              </a>
            </span>
            <span>
              <a rel="noopener noreferrer" target="_blank" href="https://beian.miit.gov.cn/">
                京ICP备17062567号
              </a>
            </span>
            <span>壹点壹滴互联（北京）科技有限公司</span>
          </div>
        </div>
        <div className="middle">
          <a href="https://file.1d1d100.com/beian/zengzhidianxin.jpg" target="_blank">
            <span>增值电信业务经营许可证 京B2-20190397</span>
          </a>
          <a href="https://file.1d1d100.com/beian/wangluowenhua.jpg" target="_blank">
            <span>网络文化经营许可证 京网文【2018】9948-871号</span>
          </a>
          <a href="https://file.1d1d100.com/beian/guangbodianshi.jpg" target="_blank">
            <span>广播电视节目制作经营许可证 (京)字第12169号</span>
          </a>
          <span>统一社会信用代码：911101083182948709</span>
        </div>
        <div className="right">
          <p className="social">
            <img src={IconWx} alt="微信图标" />
            <img className="qr" src={QrWx} alt="微信二维码" />
          </p>
          <p className="social">
            <img src={IconTt} alt="头条图标" />
            <img className="qr" src={QrTt} alt="头条二维码" />
          </p>
          <p className="social">
            <img src={IconDy} alt="抖音图标" />
            <img className="qr" src={QrDouyin} alt="抖音二维码" />
          </p>
        </div>
      </div>
    </Wrapper>
  );
}
