import React from 'react';
import styled from 'styled-components';
import Container from '../__Container';
import Banner from '../__Banner';
import Section from './_Section';
import Data from './_data';
import BannerImg from 'imgs/1d1d/pc/banner.culture.png';

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(250, 250, 250);
  > section:nth-child(2n) {
    background: #fff;
  }
`;
export default function Culture() {
  return (
    <Container>
      <Banner
        banner={BannerImg}
        h1="文化与价值观"
        h1ani="fade-left"
        h2s={['culture and values']}
        h2ani="fade-right"
      />
      <Sections>
        {Data.map(s => {
          return <Section {...s} key={s.title} />;
        })}
      </Sections>
    </Container>
  );
}
