import React from 'react';
import { ContactWrap, Title, Desc } from './styled';
import { CONTACT_DATA } from './data';

export default function Contact() {
  return (
    <ContactWrap>
      <Title>联系我们</Title>
      <div className="content-box">
        {CONTACT_DATA.map((item) => (
          <div className="cb-item" key={item.label}>
            <Desc>{item.label}</Desc>
            <div className="cb-item-content">
              {item.content.map((cItem) => (
                <div className="cbc-item" key={cItem.label}>
                  <pre className="cbc-item-label">{`${cItem.label || ''}：`}</pre>
                  <div className="cbc-item-value">{cItem.value}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </ContactWrap>
  );
}
