import styled from 'styled-components';

export const Wrapper = styled.footer`
  width: 100%;
  padding-top: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    padding-bottom: 4.5rem;
    border-bottom: 1px solid #eeeeee;
  }
  .copyright {
    color: #b5b5b5;
    line-height: 2.4rem;
    font-size: 0.7rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
`;

export const Title = styled.div`
  font-size: 0.9rem;
  color: #565656;
  line-height: 1;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const Desc = styled.div`
  font-size: 0.7rem;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  color: 565656;
  line-height: 1rem;
  margin-bottom: 0.5rem;
`;

export const ContactWrap = styled.section`
  margin-right: 4rem;
  .content-box {
    display: flex;
    line-height: 1rem;
    .cb-item {
      &:first-child {
        margin-right: 2.6rem;
      }
    }
    .cbc-item {
      display: flex;
      font-size: 0.6rem;
      line-height: 1rem;
      margin-bottom: 0.4rem;
      &-label,
      &-value {
        color: #565656;
      }
      &-label {
        flex: 0 0 2.7rem;
      }
      &-value {
        flex-shrink: 0;
      }
    }
  }
`;

export const LinksWrap = styled.section`
  font-weight: 400;
  font-size: 0.6rem;
  color: #565656;
  line-height: 1rem;
  margin-right: 3.3rem;
  .content-box {
    display: flex;
    flex-direction: column;
    > a {
      margin-bottom: 0.4rem;
    }
  }
`;

export const QrcodesWrap = styled.section`
  .content-box {
    display: flex;
    & > img {
      width: 4.36rem;
      height: 4.36rem;
      display: block;
    }
    .first {
      margin-right: 3.8rem;
    }
  }
`;
