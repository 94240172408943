import React from 'react';
import Container from '../__Container';

import Banner from '../__Banner';
import Feats from './_Features';
import Conditions from './_Conditions';
import BePartner from './_BePartner';
import BannerImg from 'imgs/1d1d/pc/banner.partner.png';

export default function Partner() {
  return (
    <Container>
      <Banner
        banner={BannerImg}
        h1="诚征区域合作伙伴"
        h2s={['regional partner']}
        desc={['全国已有400位合伙人，每个地区仅招募一名合伙人']}
      />
      <Feats />
      <Conditions />
      <BePartner />
    </Container>
  );
}
