import React from 'react';
import styled from 'styled-components';
import Container from '../__Container';
import Banner from '../__Banner';
import BannerImg from 'imgs/1d1d/pc/banner.founder.png';

import FounderImg from 'imgs/1d1d/pc/founder.wang.png';

const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6.4rem 0;
  .founder {
    width: 17.7rem;
    margin-right: 4rem;
  }
  > article {
    width: 37.6rem;
    color: #2c2c2c;
    > h1 {
      font-size: 1.9rem;
      margin-bottom: 1rem;
    }
    > h2 {
      font-size: 0.9rem;
    }
    hr {
      background: #aaa;
      margin: 1.4rem 0;
      height: 0.05rem;
      border: none;
    }
    .con {
      font-size: 0.7rem;
      > p {
        color: #787878;
        margin-bottom: 1rem;
        line-height: 1.8;
      }
    }
  }
`;
export default function Service() {
  return (
    <Container>
      <Banner banner={BannerImg} h1="创始人故事" h2s={['founder']} />
      <Main>
        <img src={FounderImg} alt="创始人图片" className="founder" />
        <article>
          <h1>王红兵</h1>
          <h2>壹点壹滴创始人，红缨教育创始人，中欧国际工商学院EMBA</h2>
          <hr />
          <section className="con">
            <p>
              王红兵是带领幼教机构登陆资本市场的企业家，拥有20余年幼儿教育行业实战经验，是中国民办幼儿园领军人物。他曾作为创始人，带领红缨教育从零开始，迅速成长为幼教行业具有影响力的教育机构。
            </p>
            <p>
              2007年，王红兵受北京大学光华管理学院龙军生教授的点拨，设计并推出“赢在中国”北京红缨幼儿园连锁项目。成立至今，红缨教育已发展为中国幼儿园连锁经营的领军品牌，在全国拥有幼儿园4000多家，覆盖中国大陆全部省份，10万名幼儿教师，120万名幼儿，200万名家长从中受益。
            </p>
            <p>2011年，出版专著《从幼儿园到清华园》。</p>
            <p>
              2013年，“中国第二届民办幼儿园园长大会暨中国民办教育协会学前教育专业委员会第二次全国代表大会”上，荣获“中国民办幼儿园卓越领军人物”称号。
            </p>
            <p>2015年，带领“红缨教育”成功登陆资本市场。</p>
            <p>2015年，在“2015年亚洲幼教年会”上，荣获“民办幼儿园卓越创业者”称号。</p>
            <p>2016年，出版专著《红缨的力量》。</p>
            <p>2018年，创办中国平台“壹点壹滴”，立志将壹点壹滴打造为一家伟大的幼教互联网公司。</p>
          </section>
        </article>
      </Main>
    </Container>
  );
}
