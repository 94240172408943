import React from 'react';
import styled from 'styled-components';
// import icon from 'imgs/1d1d/pc/ketizu/research_icon.png';
import listImg from 'imgs/1d1d/pc/ketizu/research-pc.jpg';

import { BgWrap } from '../__styled';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 84px 0;
  .title-box {
    width: 65.85rem;
    background-color: rgba(255, 255, 255, 0.95);
    box-sizing: border-box;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
    margin: 0 auto;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      width: 100%;
    }
    h1 {
      font-size: 1.3rem;
      color: #636363;
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 0.9rem;
      color: #636363;
      line-height: 1.55rem;
    }
  }
`;

export default function Verify({ banner }) {
  return (
    <Wrapper>
      <BgWrap bg={banner} />
      <div className="title-box">
        <img src={listImg} alt="" />
        {/* <img src={icon} alt="" /> */}
        {/* <h1>课题实验基地正在审核中</h1>
        <h2>审核结果将于近期公布，请您耐心等待。</h2>
        <h2>感谢您对“信息化背景下的家庭教育研究”课题的支持。</h2> */}
      </div>
    </Wrapper>
  );
}
