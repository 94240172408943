import ImgHbsq from 'imgs/1d1d/pc/product.hbsq.png';
import ImgYett from 'imgs/1d1d/pc/product.yett.png';
import ImgJygy from 'imgs/1d1d/pc/product.jygy.png';
import ImgYsjs from 'imgs/1d1d/pc/product.ysjs.png';

const Sections = [
  {
    title: '哄宝神器',
    subTitle: '甄选优质育儿内容，轻松缓解育儿烦恼',
    content:
      '“幼教云”倾力打造互联网育儿小程序——哄宝神器，壹点壹滴专家团队联合中国前沿的儿童心理专家、睡眠专家、学前教育专家，从近10万册获奖绘本、内容、经典动画、国学内容中精心筛选，为广大家长提供大量免费的故事、儿童、国学、音乐和课程，随时随地为家长提供幼儿哄睡、叫早等多元化的育儿内容，解放家长，成长孩子。',
    img: ImgHbsq
  },
  {
    title: '精品课程',
    subTitle: '整合优秀儿童教育资源，助力孩子全面成长',
    content:
      '“幼教云”结合幼儿成长需求，整合业内优质视频课程，包含英语、人文、财商、安全、创意、美学等，全面培养幼儿学习兴趣，为孩子的成长保驾护航。',
    img: ImgYett
  },
  {
    title: '家园共育',
    subTitle: '用专业重建家园关系，智能连接幼师、家长',
    content:
      '“幼教云”打造系统、专业的家园共育互联网新模式，帮助园所打破家园沟通黑匣子，手把手指导老师做好家长工作，老师、家长联动科学育儿，构建良好的家园关系，让家长真正成为幼儿教育的同盟军。',
    img: ImgJygy
  },
  {
    title: '园所介绍',
    subTitle: '全方位打造幼儿园品牌形象',
    content:
      '从师资、课程、园所环境、卫生条件、膳食搭配等方面对“幼教云”合作园进行深度介绍，为幼儿园锻造专属品牌，家长通过公众号可进行线上咨询、预约参观。为幼儿园和家长搭建了一座互联网桥梁。',
    img: ImgYsjs
  }
];
export default Sections;
