import LogoKdxf from 'imgs/1d1d/pc/coop.logo.kdxf.png';
import LogoFzjt from 'imgs/1d1d/pc/coop.logo.fzjt.png';
import LogoPd from 'imgs/1d1d/pc/coop.logo.pd.png';
import LogoSjjc from 'imgs/1d1d/pc/coop.logo.sjjc.png';
import LogoFysw from 'imgs/1d1d/pc/coop.logo.fysw.png';
import LogoXet from 'imgs/1d1d/pc/coop.logo.xet.png';
import LogoHxr from 'imgs/1d1d/pc/coop.logo.hxr.png';
import LogoBbjh from 'imgs/1d1d/pc/coop.logo.bbjh.png';
import LogoHhsw from 'imgs/1d1d/pc/coop.logo.hhsw.png';
import LogoQby from 'imgs/1d1d/pc/coop.logo.qby.png';

const Sections = [
  {
    title: 'company',
    subTitle: '壹点壹滴',
    desc: [
      [
        //暂时注释
        // 及“紫荆儿童成长云平台”
        '壹点壹滴以互联网技术为驱动，以幼儿园公众号为载体，搭建幼教云平台，通过“幼教云园所服务平台”和“幼教云家长服务平台”分别针对幼儿园和家长进行全面赋能。'
      ],
      [
        '我们期待和国内外的优秀企业一起，',
        '共同携手推进中国幼教行业的发展，创造美好的明天。',
        '如果您想做幼教，可以找我们；',
        '如果您想探索“互联网+幼教”等于什么，可以找我们；',
        '如果您想让您的产品在最短的时间进到全国各地幼儿园，可以找我们；',
        '如果您有好的项目，优质的资源，更可以找我们。'
      ]
    ]
  },
  {
    title: 'cooperation method',
    subTitle: '合作方式',
    desc: [
      [
        '战略合作：和壹点壹滴达成战略合作伙伴，彼此共享资源，共创辉煌。',
        '项目合作：如果您拥有优质项目或资源，可以依托壹点壹滴强大的互联网平台，共同开发和经营。',
        '广告合作：依托壹点壹滴平台和客源资源，帮助您实现信息的精准传播。'
      ],
      ['未来充满无限想象，我们希望能跟你并肩前行！']
    ]
  },
  {
    title: 'cooperation brand',
    subTitle: '合作品牌',
    logos: [
      LogoKdxf,
      LogoFzjt,
      LogoPd,
      LogoSjjc,
      LogoFysw,
      LogoXet,
      LogoHxr,
      LogoBbjh,
      LogoHhsw,
      LogoQby
    ]
  },
  {
    title: 'contact information',
    subTitle: '联系方式',
    desc: [['地址：北京市海淀区东北旺西路8号中关村软件园8号楼二层231A', '合作请发送至：scb@1d1d100.com']]
  }
];
export default Sections;
