import React from 'react';
import styled from 'styled-components';
import Container from '../__Container';

import Banner from '../__Banner';
import Section from './_Section';
import Data from '../data/_spaceData';
import BannerImg from 'imgs/1d1d/pc/banner.spacesite.png';

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.6rem;
`;
export default function Space() {
  return (
    <Container>
      <Banner
        banner={BannerImg}
        h1="幼教云园所服务平台"
        h2s={['SERVICE PLATFORM OF ', 'KINDERGARTEN']}
        h2ani="fade-right"
        h1ani="fade-left"
      />
      <Sections>
        {Data.map((s, idx) => {
          return <Section {...s} reverse={idx % 2 !== 0} key={s.title} />;
        })}
      </Sections>
    </Container>
  );
}
