import React from 'react';
import GlobalStyle from '../../../pages/__Global.style';
import Header from './header';
import Footer from './footer';
import { Wrapper, Content } from './styled';

export default ({ children }) => (
  <>
    <GlobalStyle />
    <Wrapper>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  </>
);
