import React from 'react';
import styled from 'styled-components';
import { Enables } from './_data';
import SixImg from 'imgs/1d1d/pc/intro.six.png';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.6rem 0 6.6rem 0;
  background: #fafafa;
  > h2 {
    color: #2c2c2c;
    font-size: 1.8rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 2.9rem;
      height: 0.2rem;
      background: #ff7c34;
      left: 50%;
      transform: translateX(-50%);
      bottom: -4rem;
    }
  }
  > h3 {
    color: #868686;
    font-size: 1.1rem;
    margin-top: 1rem;
    margin-bottom: 6.8rem;
  }
  .enables {
    display: flex;
    .list {
      display: flex;
      flex-direction: column;
      width: 9.8rem;
      justify-content: space-around;

      .enable {
        /* padding-top: 0.4rem; */
        min-height: 4.4rem;

        > h4 {
          color: #2c2c2c;
          font-size: 0.9rem;
          position: relative;
          .line {
            position: absolute;
            box-sizing: content-box;
            top: 4px;
            right: -2.3rem;
            width: 5px;
            height: 5px;
            border: 4px solid #ccc;
            border-radius: 50%;
            :after {
              content: '';
              display: block;
              width: 6rem;
              height: 2px;
              background-image: linear-gradient(
                90deg,
                #ccc,
                #ccc 50%,
                transparent 50%,
                transparent 100%
              );
              background-size: 4px 4px;
              top: 50%;
              position: absolute;
              right: 0;
              transform: translateX(110%);
              transform: translate(110%, -50%);
            }
          }
        }
        > p {
          margin-top: 0.4rem;
          color: #787878;
          font-size: 0.7rem;
          line-height: 1.6;
        }
      }
      &.left {
        text-align: right;
      }
      &.right {
        text-align: left;
        .enable > h4 {
          .line {
            left: -2.3rem;
            :after {
              left: 0;
              transform: translate(-110%, -50%);
            }
          }
        }
      }
    }

    .middle {
      height: 19.5rem;
      margin: 0 1.5rem;
      padding: 0 8.4rem;
      > img {
        height: 100%;
      }
    }
  }
`;
export default function Banner() {
  return (
    <Wrapper>
      <h2>壹点壹滴用专业内容和互联网技术为幼儿园赋能</h2>
      <h3>全国已有20000+幼儿园选择使用新幼教云平台</h3>
      <div className="enables">
        <div className="list left">
          {Enables.slice(0, 3).map((able) => {
            const { title, desc } = able;
            return (
              <div key={title} className={`enable`}>
                <h4>
                  {title}
                  <div className="line"></div>
                </h4>
                <p>{desc}</p>
              </div>
            );
          })}
        </div>
        <div className="middle">
          <img src={SixImg} alt="6大赋能" className="pic" />
        </div>
        <div className="list right">
          {Enables.slice(3).map((able) => {
            const { title, desc } = able;
            return (
              <div key={title} className={`enable`}>
                <h4>
                  {title} <div className="line"></div>
                </h4>
                <p>{desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}
