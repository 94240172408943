import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  background: rgb(250, 250, 250);
  position: absolute;
  top: 1.6rem;
  left: 0.8rem;
  > a {
    text-decoration: none;
    padding: 0.7rem 1.7rem;
    border: 1px solid rgb(241, 241, 241);
    border-radius: 0.3rem;
    margin-right: 1rem;
    font-size: 0.8rem;
    &.selected {
      color: #fff;
      background: #ff7c34;
    }
  }
`;
const data = [
  {
    title: '企业动态',
    link: '/news/'
  },
  {
    title: '点滴人物',
    link: '/peoples/'
  },
  {
    title: '视频专区',
    link: '/videos/'
  }
];
export default function Tabs({ selected = 0 }) {
  return (
    <Wrapper>
      {data.map((link, idx) => {
        return (
          <Link className={selected == idx ? 'selected' : ''} key={link.link} to={link.link}>
            {link.title}
          </Link>
        );
      })}
    </Wrapper>
  );
}
