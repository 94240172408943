import React from 'react';
import styled from 'styled-components';
import IconSearch from 'imgs/1d1d/pc/icon.search.png';

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4.3rem 2.9rem 3rem 2.9rem;
  .btn {
    cursor: pointer;
    padding: 0.4rem 0;
    padding-right: 1.2rem;
    padding-left: 1.8rem;
    background-color: #ff7c34;
    font-size: 0.9rem;
    color: #fff;
    border: none;
    background-image: url(${IconSearch});
    background-repeat: no-repeat;
    background-position-x: 0.5rem;
    background-size: 1rem;
    background-position-y: center;
  }
  .inputs {
    display: flex;
    .input {
      display: flex;
      align-items: center;
      border: 2px solid rgb(187, 187, 187);
      padding: 0.6rem 0.4rem;
      padding-right: 1rem;
      margin-right: 0.6rem;
      > label {
        color: #818181;
        font-size: 0.8rem;
      }
      select,
      input {
        border: none;
        font-size: 0.8rem;
      }
      select {
        background: none;
      }
    }
  }
`;
export default function Search({
  cates,
  departs,
  locations,
  searchCate,
  searchDepart,
  searchKeyword,
  searchLocation,
  handleSearchChange,
  handleSearch
}) {
  return (
    <SearchWrapper>
      <div className="inputs">
        <div className="input select">
          <label>职位分类:</label>
          <select data-key="cate" value={searchCate} onChange={handleSearchChange}>
            <option value="">不限制</option>
            {cates.map(c => {
              return (
                <option key={c} value={c}>
                  {c}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input select">
          <label>部门:</label>
          <select data-key="depart" value={searchDepart} onChange={handleSearchChange}>
            <option value="">不限制</option>
            {departs.map(d => {
              return (
                <option key={d} value={d}>
                  {d}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input select">
          <label>工作地点:</label>
          <select data-key="location" value={searchLocation} onChange={handleSearchChange}>
            <option value="">不限制</option>
            {locations.map(l => {
              return (
                <option key={l} value={l}>
                  {l}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input">
          <input
            data-key="keyword"
            value={searchKeyword}
            onChange={handleSearchChange}
            type="text"
            placeholder="请输入职位关键词"
          />
        </div>
      </div>
      <button onClick={handleSearch} className="btn">
        搜索
      </button>
    </SearchWrapper>
  );
}
