import React, { useState, useEffect, useCallback } from 'react';
import Container from '../__Container';
import styled from 'styled-components';
import { createForm } from 'rc-form';
import 'url-search-params-polyfill';
import Cascaders from './__Cascader';
import Toast from './__Toast';
import GlobalStyle from './__Global.style';

const Wrapper = styled.section`
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  form {
    button[type='submit'] {
      cursor: pointer;
      font-size: 0.9rem;
      width: 20.7rem;
      height: 2.9rem;
      /* background: #9c9c9c; */
      background: #ff7c34;
      color: #fff;
      border: none;
      outline: none;
    }
    .input {
      width: 20.7rem;
      height: 2.9rem;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      /* padding: 0.1rem 0.1rem 0.1rem 0; */
      /* justify-content: space-between; */
      &.code {
        /* justify-content: space-between; */
        position: relative;
        .btn {
          position: absolute;
          right: 0.15rem;
          top: 0.1rem;
          cursor: pointer;
          border: none;
          outline: none;
          /* background: ; */
          background: ${props => (props.count == 0 ? '#ff7c34' : '#d6d6d6')};
          /* color: #636363; */
          color: #fff;
          height: 2.55rem;
          line-height: 2.55rem;
          text-align: center;
          padding: 0 1rem;
          width: 7rem;
        }
      }
      label {
        /* width: 2.8rem; */
        /* text-align: justify; */
        flex: 2;
        color: #000;
        margin-left: 1.1rem;
        /* margin-right: 2rem; */
      }
      input {
        flex: 10;
        height: 100%;
        border: none;
        &:placeholder {
          color: #9f9f9f;
        }
      }
    }
  }
`;
// https://api.1d1d100.com/offical/post_mess
// https://api.1d1d100.com/offical/provice
// https://api.1d1d100.com/weixin/common/smssend/18201385843
function Apply({ form }) {
  const { getFieldProps } = form;
  const [mobile, setMobile] = useState('');
  const [count, setCount] = useState(0);
  const [region, setRegion] = useState([]);
  const [address, setAddress] = useState('');
  const [toast, setToast] = useState('');
  const [value, setValue] = useState('');
  const startCountdown = () => {
    setCount(60);
  };
  useEffect(() => {
    let interval;
    // 开始倒计时
    if (count) {
      interval = setInterval(() => {
        setCount(preCount => {
          if (preCount <= 1) {
            setCount(0);
            clearInterval(interval);
          } else {
            return preCount - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [count]);

  const handleCodeClick = async () => {
    if (count) {
      return;
    }
    form.validateFields(async (err, values) => {
      const { mobile = '' } = err;
      if (mobile) {
        const msg = err['mobile'].errors[0].message;
        setToast(msg);
      } else {
        const resp = await fetch(`https://api.1d1d100.com/weixin/common/smssend`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...values })
        });
        const { code } = await resp.json();
        if (code == 0) {
          startCountdown();
        } else {
          alert('验证码发送失败');
        }
      }
    });
  };
  useEffect(() => {
    const M = new URLSearchParams(window.location.search).get('mobile') || '';
    setMobile(M);
  }, []);

  //获取地区数据
  const getRegion = useCallback(async () => {
    const resp = await fetch(`https://api.1d1d100.com/offical/provice`);
    const { data } = await resp.json();
    const region = data.map(item => ({
      value: item.id,
      label: item.name,
      type: item.type,
      isLeaf: false
    }));
    setRegion(region);
  }, []);
  useEffect(() => {
    getRegion();
  }, [getRegion]);

  const handleSubmit = evt => {
    evt.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        if (!address) {
          setToast('请选择所在地区');
          return;
        }
        const resp = await fetch(`https://api.1d1d100.com/offical/post_mess`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...values, ...address })
        });
        const { code, msg } = await resp.json();
        if (code == 0) {
          setToast('申请成功');
          form.resetFields();
          setValue('');
        } else {
          setToast(msg);
        }
      } else {
        const msg = err[Object.keys(err)[0]].errors[0].message;
        setToast(msg);
      }
    });
  };
  const checkRegion = data => {
    setRegion(data);
  };
  const checkValue = data => {
    setValue(data.address);
    setAddress(data);
  };
  const remove = () => {
    setToast('');
  };
  return (
    <Container>
      <GlobalStyle />
      {toast && <Toast text={toast} remove={remove} />}
      <Wrapper count={count}>
        <form onSubmit={handleSubmit}>
          <div className="input">
            <label htmlFor="mobile">手 机 号</label>
            <input
              type="tel"
              maxLength={11}
              placeholder="请输入手机号"
              {...getFieldProps('mobile', {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号'
                  },
                  {
                    pattern: /^[1][0-9][0-9]{9}$/,
                    message: '请输入正确的手机号'
                  }
                ],
                initialValue: mobile
              })}
            />
          </div>
          <div className="input code">
            <label htmlFor="code">验 证 码</label>
            <input
              type="text"
              maxLength={6}
              placeholder="请输入验证码"
              {...getFieldProps('code', {
                rules: [{ required: true, message: '请输入验证码' }],
                initialValue: ''
              })}
            />
            <div className="btn" onClick={handleCodeClick} disabled={count > 0}>
              {count > 0 ? count : `获取验证码`}
            </div>
          </div>
          <div className="input">
            <label htmlFor="schoolName">园所名称</label>
            <input
              maxLength={20}
              type="text"
              placeholder="请输入园所名称"
              {...getFieldProps('schoolName', {
                rules: [{ required: true, message: '请输入园所名称' }],
                initialValue: ''
              })}
            />
          </div>
          <div className="input addr">
            <label htmlFor="addr">所在地区</label>
            <Cascaders
              options={region}
              placeholder="请选择所在地区"
              checkRegion={checkRegion}
              checkValue={checkValue}
              value={value}
            />
          </div>
          <button type="submit">立即申请</button>
        </form>
      </Wrapper>
    </Container>
  );
}

export default createForm()(Apply);
