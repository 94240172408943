import React from 'react';
import styled from 'styled-components';
const TableWrapper = styled.table`
  /* border: none; */
  text-align: center;
  width: 100%;
  thead {
    tr.placeholder {
      height: 4rem;
    }
    tr:first-child {
      border-bottom: 1px solid #ff7c34;
      th {
        position: relative;
        padding: 1.2rem 0;
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 7rem;
          height: 4px;
          background: #ff7c34;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  tbody {
    tr.pos:nth-child(2n + 1) {
      background: #f9f9f9;
    }
    tr.pos {
      cursor: pointer;
      &:hover {
        background: rgba(255, 124, 52, 0.1);
      }
      td {
        padding: 1rem 0;
      }
    }
  }
`;

export default function Table({ data = [] }) {
  const handlePosClick = link => {
    window.open(link, true);
  };
  return (
    <TableWrapper>
      <thead>
        <tr>
          <th width="130">职位名称</th>
          <th width="200">职位类别</th>
          <th width="120">工作地点</th>
          <th width="140">部门</th>
          <th width="140">更新时间</th>
        </tr>
        <tr className="placeholder"></tr>
      </thead>
      <tbody>
        {data.length ? (
          data.map(pos => {
            return (
              <tr className="pos" onClick={handlePosClick.bind(null, pos.link)} key={pos.id}>
                <td>{pos.title}</td>
                <td>{pos.cate}</td>
                <td>{pos.location}</td>
                <td>{pos.depart}</td>
                <td>{pos.updateTime}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="6">无数据</td>
          </tr>
        )}
      </tbody>
    </TableWrapper>
  );
}
