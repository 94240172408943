import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import REot from 'fonts/RockoUltraFLF.eot';

import RSvg from 'fonts/RockoUltraFLF.svg';
import RTtf from 'fonts/RockoUltraFLF.ttf';
import RWoff from 'fonts/RockoUltraFLF.woff';

import ItTf from 'fonts/ITCAvantGardeStd-XLt.ttf';
import ItWoff from 'fonts/ITCAvantGardeStd-XLt.woff';

const GlobalStyle = createGlobalStyle`
  ${reset}
  *{
    box-sizing:border-box;
    outline:none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0.2);
    color:#2C2C2C;
  }
  a{
    text-decoration:none;
  }
  html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size:14px;
  }
  body{
    -webkit-overflow-scrolling: touch;
    overflow:scroll;
    margin:0 auto;
    width:100%;
    min-height:100vh;
    position: relative;
    background:#fff;
    font-weight:400;
    font-family:"Microsoft Yahei", tahoma, Srial, helvetica, sans-serif;

  }
  
   @media screen and (min-width: 1000px){
      html {
          font-size: 12px;
      }
  }
   @media screen and (min-width: 1100px){
      html {
          font-size: 14px;
      }
  }
   @media screen and (min-width: 1200px){
      html {
          font-size: 16px;
      }
  }
  
 
  @media screen and (min-width: 1400px){
      html {
          font-size: 18px;
      }
  } 
  @media screen and (min-width: 1600px){
      html {
          font-size: 20px;
      }
  } 
@font-face {
  font-family: "RockoUltraFLF";
  src: url(${REot}) format("eot"),
    url(${RWoff}) format("woff"),
    url(${RTtf}) format("truetype"),
    url(${RSvg}) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd-XLt';
  src: url(${ItTf}) format('truetype'),
       url(${ItWoff}) format('woff');
  font-weight: normal;
  font-style: normal;
}
`;

export default GlobalStyle;
