/* eslint-disable no-irregular-whitespace */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'url-search-params-polyfill';
import Container from '../__Container';

import Banner from '../__Banner';
import BannerImg from 'imgs/1d1d/pc/banner.welcome.png';

import TabValues from 'imgs/1d1d/pc/welcome.tab.values.png';
import ImgValues from 'imgs/1d1d/pc/welcome.values.png';
import TabProduct from 'imgs/1d1d/pc/welcome.tab.product.png';
import TabAbout from 'imgs/1d1d/pc/welcome.tab.about.png';
import BgImg from 'imgs/1d1d/pc/welcome.bg.png';

const Tabs = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  padding: 4rem 0;
  background-image: url(${BgImg});
  background-color: #fefbf4;
  background-size: 100% 100%;
  .tabs {
    width: 70.5rem;
    height: 26rem;
    background: rgb(255, 255, 255);
    box-shadow: 0px 10px 16px 2px rgba(83, 83, 83, 0.17);
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tab {
        position: relative;
        cursor: pointer;
        height: 100%;
        &:not(:last-child) {
          margin-bottom: 0.4rem;
        }
        > img {
          width: 19.6rem;
          height: 100%;
        }
        &.selected:after {
          position: absolute;
          content: '';
          display: block;
          width: 4.3rem;
          height: 4px;
          background: rgb(255, 124, 52);
          top: 60%;
          transform: translateY(-50%);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .right {
      position: relative;
      .con {
        padding: 2.8rem 4.3rem 2.8rem 4.3rem;

        > img {
          width: 43.8rem;
        }
        .more {
          position: absolute;
          right: 3rem;
          bottom: 1rem;
          border: 2px solid rgb(255, 124, 52);
          border-radius: 10px;
          color: rgb(255, 124, 52);
          font-size: 0.8rem;
          padding: 0.6rem 1.2rem;
        }
        &.product {
          .more {
            bottom: 2rem;
          }
          > h2 {
            color: rgb(255, 124, 52);
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 4rem;
          }
          .intro {
            padding-right: 1rem;
            height: 10rem;
            overflow: scroll;
            > h3 {
              font-weight: bold;
              font-size: 1.1rem;
              margin-bottom: 1.4rem;
            }
            > p {
              font-size: 0.9rem;
              color: #636363;
              line-height: 1.6;
              &:not(:last-child) {
                margin-bottom: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
`;
const TabData = [
  {
    img: TabAbout,
    title: '关于壹点壹滴'
  },
  {
    img: TabProduct,
    title: '核心产品'
  },
  {
    img: TabValues,
    title: '文化价值观'
  }
];
export default function Welcome() {
  const [currTab, setCurrTab] = useState(2);
  const handleTabClick = (evt) => {
    const { seq } = evt.currentTarget.dataset;
    console.log(evt.currentTarget.dataset.seq);
    if (seq == 1) {
      location.href = '/intro/';
      return;
    }
    setCurrTab(seq);
  };
  useEffect(() => {
    const selected = new URLSearchParams(window.location.search).get('selected') || 2;
    console.log(selected);

    setCurrTab(selected);
  }, []);
  return (
    <Container>
      <Banner banner={BannerImg} h1="欢迎加入壹点壹滴" h2s={['welcome to join us']} />
      <Tabs>
        <div className="tabs">
          <div className="left">
            {TabData.map((tab, idx) => {
              const seq = idx + 1;
              return (
                <div
                  data-seq={seq}
                  onClick={handleTabClick}
                  key={tab.title}
                  className={`tab ${currTab == seq ? 'selected' : ''}`}
                >
                  <img src={tab.img} alt={tab.title} />
                </div>
              );
            })}
          </div>
          <div className="right">
            {currTab == 1 && (
              <div className="con">
                <img src={ImgValues} alt="文化价值观内容" />
                <a className="more" href="/intro">
                  了解详情
                </a>
              </div>
            )}
            {currTab == 2 && (
              <div className="con product">
                <h2>核心产品</h2>
                <div className="intro">
                  <h3>幼儿园端</h3>
                  <p>
                    壹点壹滴新幼教空间站：为幼儿园提供一站式解决方案，帮助幼儿园从招生、师训、管理、家园共育等方案实现全面升级，彻底解放园长，成就教师。
                  </p>
                  <p>
                    幼儿园家庭教育服务平台：集亲子早教、家园共育于一体，整合家长非常看重的教育内容，通过便捷的、高质量的线上幼儿教育服务，将幼儿园和家长有效的链接起来，赋能家长，助力幼儿园实现家园共育。
                  </p>
                  <h3>紫荆新父母新家庭教育平台</h3>
                  <p>
                    借助互联网技术和专业内容，全方位打造符合孩子发展特点的家庭教育解决方案，为孩子的健康成长保驾护航。
                  </p>
                </div>
                <a className="more" href="/intro">
                  了解详情
                </a>
              </div>
            )}
            {currTab == 3 && (
              <div className="con">
                <img src={ImgValues} alt="文化价值观内容" />
                <a className="more" href="/culture/">
                  了解详情
                </a>
              </div>
            )}
          </div>
        </div>
      </Tabs>
    </Container>
  );
}
