import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  display: flex;
  padding: 0.8rem 0.5rem;
  margin-bottom: 2rem;
  &.top {
    /* background-color: #333; */
  }
  &:hover {
    box-shadow: 0px 0px 7px 1px rgba(129, 129, 129, 0.24);
    border-radius: 5px;
    .txt > h2 {
      color: #ff7c34;
    }
  }
  .pic {
    width: 11.2rem;
    height: 6.4rem;
    border-radius: 5px;
    overflow: hidden;
    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .txt {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0.9rem;
    width: 20.8rem;

    > h2 {
      color: #2c2c2c;
      font-size: 0.9rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    > h3 {
      height: 3rem;
      margin: 0.6rem 0 1.2rem 0;
      color: #636363;
      font-size: 0.8rem;
      line-height: 1.4;
      -webkit-line-clamp: 3;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
    > time {
      color: #969696;
      font-size: 0.7rem;
    }
  }
`;
export default function Banner({
  id,
  title,
  link,
  description,
  thumbnail,
  date,
  isTop,
  detailPrefix
}) {
  // 置顶加个class ，目测以后会用到的
  return (
    <Wrapper
      className={`${isTop ? 'top' : ''}`}
      href={link ? link : `${detailPrefix}/${id}/`}
      target="_blank"
    >
      <div className="pic">
        <img className="img" src={thumbnail} alt="Banner" />
      </div>
      <div className="txt">
        <h2>{title}</h2>
        <h3>{description}</h3>
        <time>{date}</time>
      </div>
    </Wrapper>
  );
}
