import React from 'react';
import bannerIcon from 'imgs/1d1d/pc/ketizu/research_banner.png';
import bannerIcon2 from 'imgs/1d1d/pc/ketizu/research_banner_2.png';
import Layout from '../../../components/keti/layout';
import Banner from './__banner';
import Verify from './__verify';

export default function KetizuResearch() {
  return (
    <Layout>
      <Banner banner={bannerIcon} />
      <Verify banner={bannerIcon2} />
    </Layout>
  );
}
