import React from 'react';
import { Helmet } from 'react-helmet';

import Agreement from 'RootCommon/child_agreement';
const ChildAgreement = () => {
  return (
    <div>
      <Helmet title="儿童个人信息保护政策" />
      <Agreement />
    </div>
  );
};

export default ChildAgreement;
