import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.5rem 0 6.4rem 0;
  width: 65.1rem;
  .titles {
    display: flex;
    justify-content: space-between;
    width: 100%;
    > h1 {
      color: #ff7c34;
      font-size: 2.6rem;
      width: 20rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    > h2 {
      color: #2c2c2c;
      font-size: 2.5rem;
    }
  }
  .desc {
    width: 44.5rem;
    margin-top: 3.3rem;
    align-self: flex-start;
    > p {
      display: flex;
      flex-direction: column;
      font-size: 0.7rem;
      color: #636363;
      line-height: 1.6;
      &:not(:last-child) {
        margin-bottom: 1.4rem;
      }
    }
  }
  .logos {
    /* width: 44.5rem; */
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    margin-top: 3.3rem;
    > img {
      width: 10rem;
      height: 100%;
      margin-right: 1.2rem;
      margin-bottom: 1rem;
    }
  }
`;
export default function Section({ title, subTitle, logos = null, desc }) {
  return (
    <Wrapper>
      <div data-aos="fade-down" className="titles">
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
      </div>
      {desc && (
        <div data-aos="fade-up" className="desc">
          {desc.map((ps, idx) => {
            return (
              <p key={idx}>
                {ps.map(p => (
                  <span key={p}>{p}</span>
                ))}
              </p>
            );
          })}
        </div>
      )}
      {logos && (
        <div data-aos="fade-up" className="logos">
          {logos.map((logo, idx) => (
            <img key={idx} src={logo} alt="coop logo" />
          ))}
        </div>
      )}
    </Wrapper>
  );
}
