import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5.2rem;
  > h2 {
    font-size: 1.8rem;
    color: #636363;
    font-weight: 800;
    position: relative;
    margin-bottom: 2.3rem;
    font-weight: bold;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1rem;
      width: 2.3rem;
      height: 0.2rem;
      background: #ff7c34;
    }
  }
  .sec {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: -2rem;
    .pic {
      width: 30rem;
      z-index: 99;
      > img {
        width: 100%;
      }
    }
    .shadow {
      position: absolute;
      width: 37.1rem;
      height: 21.6rem;
      top: 2.5rem;
      right: -2rem;
      background: repeating-linear-gradient(-45deg, #f2f2f2, #f2f2f2 2px, #dedede 2px, #dedede 4px);
    }
    .txt {
      width: 33.1rem;
      height: 20rem;
      position: relative;
      padding: 1.8rem 2.2rem 1.4rem 1.8rem;
      background: #fff;
      border: 4px solid #ff7c34;
      border-left: none;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      > h3 {
        position: absolute;
        top: -1.3rem;
        right: 1rem;
        font-size: 1.8rem;
        color: #636363;
        font-weight: 800;
        background: #fff;
        padding: 0.4rem 2rem;
        font-weight: bold;
      }
      > p {
        position: relative;
        line-height: 1.8;
        font-size: 0.7rem;
        color: #787878;
      }
      .p {
        > h4 {
          position: relative;

          color: #2c2c2c;
          font-size: 0.7rem;
          font-weight: 800;
          margin-bottom: 0.4rem;
        }
        > p {
          position: relative;

          line-height: 1.8;
          font-size: 0.7rem;
          color: #787878;
        }
      }
    }
  }
  &.rev .sec {
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: -2rem;
    .shadow {
      right: auto;
      left: -2rem;
      background: repeating-linear-gradient(45deg, #f2f2f2, #f2f2f2 2px, #dedede 2px, #dedede 4px);
    }
    .txt {
      border: 4px solid #ff7c34;
      border-right: none;
      > h3 {
        left: 1rem;
        right: auto;
      }
    }
  }
`;
export default function Banner({ title, subTitle, desc, img, content, reverse = false }) {
  return (
    <Wrapper className={reverse ? 'rev' : ''} id="tit">
      <h2 data-aos="fade-right">{title}</h2>
      <div className="sec">
        <div className="pic" data-aos="fade-down">
          <img className="img" src={img} alt="Banner" />
        </div>
        <div className="shadow" data-aos="fade-up" />
        <div className="txt" data-aos="slide-up">
          <h3>{subTitle}</h3>
          <p>{desc}</p>
          {content.map((c, idx) => {
            return (
              <div key={idx} className="p">
                <h4>{c.title}</h4>
                {c.list.length === 1 ? (
                  <p>{c.list[0]}</p>
                ) : (
                  c.list.map((l, i) => (
                    <p className="l" key={i}>
                      {l}
                    </p>
                  ))
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}
