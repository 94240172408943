import React from 'react';
import styled from 'styled-components';
import IconLess from 'imgs/1d1d/pc/partner.ico.less.png';
import IconKeep from 'imgs/1d1d/pc/partner.ico.keep.png';
import IconMoney from 'imgs/1d1d/pc/partner.ico.money.png';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0 6.3rem 0;
  background: #fafafa;
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #616161;
    .line {
      font-size: 0.7rem;
      line-height: 4;
    }
    .feats {
      display: flex;
      margin-top: 4rem;
      width: 53rem;
      justify-content: space-evenly;
      .feat {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 4.4rem;
        &:not(:last-child) {
          border-right: 1px solid #e0e0e0;
        }
        .icon {
          width: 6.9rem;
        }
        > h2 {
          font-size: 1.3rem;
          margin-top: 1.5rem;
        }
        > h3 {
          margin-top: 1.3rem;
          font-size: 0.7rem;
          text-align: center;
          line-height: 1.6;
        }
      }
    }
  }
`;
const Feats = [
  { title: '投资少', subTitle: '少量投资，降低风险！', icon: IconLess },
  { title: '助转型', subTitle: '让企业借助互联网的力量实现转型升级！', icon: IconKeep },
  { title: '促发展', subTitle: '全新的商业模式，找到事业发展的第二条曲线！', icon: IconMoney }
];
export default function Features() {
  return (
    <Wrapper>
      <div className="intro">
        <p className="line">
          壹点壹滴，打造真正的“数字化幼儿园”，招生、师训、管理一步到位，目前服务于20000+园所和200万+家长。
        </p>
        {/* 暂时注释 */}

        {/* <p className="line">紫荆儿童成长云平台，是专门服务3-10岁儿童的素质教育互联网平台。</p>
        <p className="line">
          “紫荆云”专注研究儿童素质教育。让全国各地的孩子享受和北京孩子同步的素质教育是我们的初心。
        </p> */}
        <p className="line">
          我们始终坚持以孩子为中心，以素质教育为本，专注儿童成长，追求卓越品质。
        </p>
        <p className="line">
          {/** /紫荆儿童成长云平台  */}
          成为幼教云的区域合伙人，你将成为新幼教云平台的地方入口！你还在等什么！
        </p>
        <div className="feats">
          {Feats.map((f, i) => {
            return (
              <div key={f.title} className="feat">
                <img data-aos="flip-right" src={f.icon} alt={f.title} className="icon" />
                <h2>{f.title}</h2>
                <h3 style={!i ? { whiteSpace: 'nowrap' } : {}}>{f.subTitle}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}
