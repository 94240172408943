import React from 'react';
import styled from 'styled-components';

import Banner from '../__Banner';
import Container from '../__Container';
import Section from './_Section';
import Data from './_data';
import BannerImg from 'imgs/1d1d/pc/banner.cooperation.png';

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4.6rem;
  > section:not(:last-child) {
    position: relative;
    &:after {
      position: absolute;
      bottom: 0rem;
      content: '';
      display: block;
      width: 100vw;
      height: 2px;
      background: #ddd;
    }
  }
`;
export default function Cooperation() {
  return (
    <Container>
      <Banner
        banner={BannerImg}
        h1="战略合作"
        h1ani="fade-up"
        h2ani="fade-down"
        h2s={['business cooperation']}
      />
      <Sections>
        {Data.map(s => {
          return <Section key={s.title} {...s} />;
        })}
      </Sections>
    </Container>
  );
}
