import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LogoImg from 'imgs/1d1d/pc/nav.logo.png';
import links from 'RootCommon/links';

const Wrapper = styled.header`
  position: fixed;
  z-index: 999;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.7rem;
  width: 100%;
  .wrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .left {
      background: #fff;
      border-radius: 5px 0px 0px 5px;
      padding: 1.2rem 2.2rem 1.1rem 2.5rem;
      box-shadow: 1px 1px 3px #aaa;
      a img {
        width: 6.4rem;
      }
    }
    .middle {
      background: #fff;
      padding: 0 6rem;
      box-shadow: 1px 1px 3px #aaa;
      display: flex;
      margin: 0 2px;
      > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > li {
          position: relative;
          height: 100%;
          display: inline-flex;
          align-items: center;
          .subs {
            display: none;
            opacity: 0;
            width: 6.6rem;
            position: absolute;
            left: 50%;
            top: 100%;
            transform: translateX(-50%);
            background: rgba(0, 0, 0, 0.6);
            padding: 0.4rem 0.6rem;
            border-radius: 0.4rem;
            flex-direction: column;
            align-items: center;
            transition: all 1s;
            &.wide {
              width: 9rem;
            }
            .link {
              padding: 0.5rem 0;
              display: block;
              color: #fff;
              font-weight: bold;
              word-break: keep-all;
            }
            &:after,
            &:before {
              bottom: 100%;
              left: 50%;
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
            }

            &:after {
              border-color: transparent;
              border-bottom-color: rgba(0, 0, 0, 0.4);
              border-width: 10px;
              margin-left: -10px;
            }
            &:before {
              border-color: transparent;
              border-bottom-color: rgba(0, 0, 0, 0.4);
              border-width: 10px;
              margin-left: -10px;
            }
          }

          &:not(:last-child) {
            margin-right: 2.3rem;
          }
          &:hover {
            .subs {
              display: flex;
              opacity: 1;
            }
          }
        }
      }
    }
    .right {
      background: #fff;
      border-radius: 0px 5px 5px 0px;
      display: flex;
      align-items: center;
      padding: 1.2rem 2.2rem 1.1rem 2.5rem;
      box-shadow: 1px 1px 3px #aaa;
      .link {
        color: #fd7523;
        text-decoration: none;
        &:not(:last-child):after {
          content: '|';
          padding: 0 1.1rem;
          color: #636363;
          opacity: 0.2;
        }
      }
      > img {
        width: 2.4rem;
      }
    }
  }
`;
// const Link = styled.a``;
export default function Nav() {
  return (
    <Wrapper>
      <div className="wrapper">
        <div className="left">
          <Link className="link" to="/">
            <img src={LogoImg} alt="壹点壹滴是中国新幼教云平台" />
          </Link>
        </div>
        <div className="middle">
          <ul>
            <li>
              <Link className="link" to="/">
                首页
              </Link>
            </li>
            <li>
              <Link className="link" to="/founders/">
                关于我们
              </Link>
              <div className="subs">
                <Link className="link" to="/founders/">
                  企业简介
                </Link>
                <Link className="link" to="/culture/">
                  文化&价值观
                </Link>
                {/* <Link className="link" to="/hire/">
                  诚聘英才
                </Link> */}
                <Link className="link" to="/contact/">
                  联系我们
                </Link>
              </div>
            </li>
            {/**暂时注释 */}
            {/* <li>
              <a className="link" href={links.zj} rel="noopener noreferrer" target="_blank">
                紫荆儿童成长云平台
              </a>
            </li> */}
            <li>
              <Link className="link" to="/intro/">
                幼教云
              </Link>
              <div className="subs wide">
                <Link className="link" to="/intro/">
                  平台介绍
                </Link>
                <Link className="link" to="/space/">
                  幼教云园所服务平台
                </Link>
                <Link className="link" to="/service/">
                  幼教云家长服务平台
                </Link>
              </div>
            </li>
            <li>
              <Link className="link" to="/partner/">
                加入我们
              </Link>
            </li>
            <li>
              <Link className="link" to="/news/">
                新闻中心
              </Link>
              <div className="subs">
                <Link className="link" to="/news/">
                  企业动态
                </Link>
                <Link className="link" to="/peoples/">
                  点滴人物
                </Link>
                <Link className="link" to="/videos/">
                  视频专区
                </Link>
              </div>
            </li>
            <li>
              <Link className="link" to="/cooperation/">
                战略合作
              </Link>
            </li>
          </ul>
        </div>
        <div className="right">
          <a className="link" href={links.headmaster}>
            园长入口
          </a>

          <a className="link" href={links.zijingPC}>
            紫荆云入口
          </a>
        </div>
      </div>
    </Wrapper>
  );
}
