import React from 'react';
import bannerIcon from 'imgs/1d1d/pc/ketizu/about_banner.png';
import Layout from '../../../components/keti/layout';
import Banner from './__banner';
import TabInfo from './__tabInfo';

export default function KetizuAbout() {
  return (
    <Layout>
      <Banner banner={bannerIcon} />
      <TabInfo />
    </Layout>
  );
}
