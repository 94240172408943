import React from 'react';
import styled from 'styled-components';
import TiziImg from 'imgs/1d1d/pc/join.tizi.png';
import links from 'RootCommon/links';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 0 8rem 0;
  > h2 {
    font-family: 'ITCAvantGardeStd-XLt';
    color: #ff7c34;
    font-size: 3.3rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1.9rem;
  }
  > h3 {
    color: #2c2c2c;
    font-size: 2.4rem;
    margin-bottom: 5.8rem;
  }
  .desc {
    font-size: 1.1rem;
    > p {
      text-align: center;
      color: #787878;
      margin-bottom: 0.8rem;
    }
  }
  .info {
    display: flex;
    margin-bottom: 2rem;
    margin-top: 3rem;

    > img {
      width: 20rem;
      margin-right: 5.6rem;
    }
    .txt {
      > h3 {
        font-weight: bold;
        font-size: 1.1rem;
        color: #2c2c2c;
      }
      > p {
        font-size: 0.8rem;
        color: #787878;
        margin-top: 1rem;
        margin-bottom: 3rem;
      }
    }
  }
  .btn {
    margin-top: 1.7rem;
    padding: 0.7rem 1.9rem;
    background: rgb(255, 124, 52);
    box-shadow: 0px 5px 6px 0px rgba(56, 56, 56, 0.14);
    border-radius: 3px;
    color: #fff;
    font-size: 1rem;
  }
`;
export default function Companion() {
  return (
    <Wrapper>
      <h2>Opening requirement</h2>
      <h3>壹点壹滴幼儿园成长伙伴</h3>
      <div className="desc">
        <p>
          在助力幼儿园全面升级的道路上，壹点壹滴一路相随。陪伴园长、教师、家长、孩子共同成长，为您解决幼儿园经营烦恼。
        </p>
      </div>
      <div className="info">
        <img src={TiziImg} alt="成长伙伴配图" />
        <div className="txt">
          <h3>★ 全面赋能幼儿园，为园所成长保驾护航</h3>
          <p>我们会在您付款后24小时内和您取得联系，确认园所信息，为您提供优质的客服服务。</p>
          <h3>★ 为园所安排专属督导，答疑解惑提升效能</h3>
          <p>我们会根据幼儿园所在地区，为您安排区域专属督导，保证幼儿园公众号的正常运营。</p>
          <h3>★ 定期沟通和培训，见证您的每一次成长</h3>
          <p>
            平台开通后，我们会定期安排总部督导对公众号的使用及运营进行区域培训，让您在学习中成长。
          </p>
        </div>
      </div>
      <a href={links.onlineChat} rel="noopener noreferrer" target="_blank" className="btn">
        在线咨询
      </a>
    </Wrapper>
  );
}
