import React from 'react';
import tabBg3 from 'imgs/1d1d/pc/ketizu/about_tab_bg3.png';
import icon5 from 'imgs/1d1d/pc/ketizu/about_icon5.png';
import styled from 'styled-components';
import { BgWrap } from '../__styled';

const ContentWrap = styled.div`
  font-size: 0.9rem;
  line-height: 1.6rem;
  text-align: justify;
  h2 {
    margin-bottom: 1.5rem;
    color: #565656;
  }
  h3 {
    color: rgba(229, 1, 19, 1);
    margin-bottom: 1.8rem;
    display: flex;
    align-items: center;
    > img {
      width: 0.9rem;
      height: 0.75rem;
      display: block;
      margin-right: 0.3rem;
    }
  }
  .list {
    margin-bottom: 1.5rem;
  }
  p {
    color: #565656;
    line-height: 2;
  }
`;

export default function TabItem3({ id }) {
  return (
    <div className="tab-content-item" id={id} data-index="2">
      <BgWrap bg={tabBg3} />
      <div className="info-box">
        <h1 className="info-box-title">实验基地</h1>
        <ContentWrap>
          <h2>本课题面向全国幼儿园招募课题实验基地，主要参与课程、师资、教学三方面的应用研究。</h2>
          <h3>
            <img className="icon" src={icon5} alt="" />
            幼儿园课题实验基地参与应用研究的具体方向有：
          </h3>
          <div className="list">
            <p>1. 课程各年龄段目标及内容的适配性研究；</p>
            <p>2. 师资培训体系的有效性研究；</p>
            <p>3. 家园携手创新机制的研究；</p>
            <p>4. 线上线下融合（OMO）模式的研究；</p>
            <p>5. 家长学校创新模式的研究；</p>
            <p>6. 家庭教育评估体系的研究；</p>
            <p>7. 家委会在家庭教育中的作用研究。</p>
          </div>
          <h3>
            <img className="icon" src={icon5} alt="" />
            凡授予“课题实验基地”光荣称号的幼儿园，将有机会获得以下学术及品牌宣传支持：
          </h3>
          <div className="list">
            <p>1. 符合条件，授予“中国教育发展战略学会教师发展专业委员会课题实验基地”铜牌；</p>
            <p>2. 有机会成为中国教育发展战略学会教师发展专业委员会会员、当选理事；</p>
            <p>3. 园长及老师每年有2次机会接受课题组专家在京的系统培训；</p>
            <p>
              4.
              获得“中国教育发展战略学会教师发展专业委员会课题实验基地园”称号的幼儿园，可以用“课题实验基地”名义进行对外宣传及招生；
            </p>
            <p>
              5.
              幼儿园能参与一年一度的课题成果申报、结题，经学术委员会审核通过，获得国家级“课题研究结题证书”；
            </p>
            <p>
              6. 符合中国教育发展战略学会章程授予“常务理事单位”、“理事单位”、“普通会员单位”牌匾；
            </p>
            <p>
              7.
              幼儿园园长、老师、家长能分别参与中国教育发展战略学会教师发展专业委员会主办的“寻找中国好园长”、“寻找中国好老师”、“寻找中国好家长”年度盛典，获得荣誉证书。
            </p>
          </div>
        </ContentWrap>
        <a className="info-box-btn" href="https://www.wjx.cn/jq/102693804.aspx">
          申报课题实验基地
        </a>
      </div>
    </div>
  );
}
