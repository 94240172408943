import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'swiper/css/swiper.min.css';

import Swiper from 'swiper';

import Contact from 'RootCommon/Contact';
import Container from './__Container';

import Qr from 'imgs/1d1d/pc/partner.qr.png';
import ArrowLeft from 'imgs/1d1d/pc/arrow.left.png';
import ArrowRight from 'imgs/1d1d/pc/arrow.right.png';
import Slide1 from 'imgs/1d1d/pc/slide.first.png';
import Slide2 from 'imgs/1d1d/pc/banner2.png';
import Slide3 from 'imgs/1d1d/pc/banner3.png';

const Slides = styled.section`
  position: relative;
  width: 100vw;
  height: 44.6rem;
  .swiper-wrapper {
    width: 100%;
    height: 100%;
  }
  .arrows {
    z-index: 999;
    position: absolute;
    bottom: 6.4rem;
    right: 16.2rem;
    display: flex;
    justify-content: space-between;
    width: 4.5rem;
    .arrow {
      width: 2rem;
      height: 100%;
      cursor: pointer;
    }
  }
  .slide {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    hgroup {
      display: flex;
      flex-direction: column;
      width: 64rem;
      > h2 {
        color: #fff;
        font-size: 2rem;
        margin-bottom: 0.6rem;
        > em {
          color: #fff;
          font-weight: bold;
        }
      }
      > h3 {
        font-weight: 800;
        color: #fff;
        font-size: 0.94rem;
        line-height: 1.4;
        padding-left: 0.2rem;
      }
    }
    &.first {
      background-image: url(${Slide1});
      background-size: cover;
      align-items: center;
      padding-top: 2rem;
    }
    &.middle {
      background-image: url(${Slide2});
      justify-content: flex-end;
      padding-bottom: 5rem;
      hgroup {
        align-items: center;
        width: 100%;
        text-align: center;
      }
    }
    &.last {
      background-image: url(${Slide3});
      justify-content: flex-end;
      padding-bottom: 13.2rem;
      hgroup {
        align-items: center;
        width: 100%;
        h2 {
          display: flex;
          align-items: center;
          .line {
            height: 2px;
            width: 3.3rem;
            background: #fff;
            display: inline-block;
          }
        }
      }
    }
  }
`;
export default function Index() {
  const container = useRef(null);
  const nextEl = useRef(null);
  const prevEl = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (container) {
        new Swiper(container.current, {
          keyboard: false,
          mousewheel: false,
          loop: true,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          navigation: {
            prevEl: prevEl.current,
            nextEl: nextEl.current
          }
        });
      }
    }, 300);
  }, []);
  return (
    <Container>
      <Slides ref={container} className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide slide first">
            <hgroup>
              <h2>
                以技术引领幼教行业<em>全面升级</em>
              </h2>
              <h3>
                Leading the overall upgrading of Preschool
                <br />
                Education Industry with Technology
              </h3>
            </hgroup>
          </div>
          <div className="swiper-slide slide middle">
            <hgroup>
              <h2>
                用爱和责任<em>推动幼儿教育公平</em>
              </h2>
              <h3>
                Promoting equity in early childhood education
                <br />
                with love and responsibility
              </h3>
            </hgroup>
          </div>
          <div className="swiper-slide slide last">
            <hgroup>
              <h2>
                壹点壹滴<span className="line"></span>
                <em>做推动行业进步的幼教互联网企业</em>
              </h2>
              {/* <h3>The pioneer of Chinese preschool education industry</h3> */}
            </hgroup>
          </div>
        </div>
        <div className="arrows">
          <img ref={prevEl} src={ArrowLeft} alt="arrow left" className="arrow left " />
          <img ref={nextEl} src={ArrowRight} alt="arrow right" className="arrow right " />
        </div>
      </Slides>
      <Contact qr={Qr} />
    </Container>
  );
}
