import React from 'react';
import styled from 'styled-components';

import WangImg from 'imgs/1d1d/pc/profile.wang.png';
import ZhangImg from 'imgs/1d1d/pc/profile.zhang.png';
import DengImg from 'imgs/1d1d/pc/profile.deng.png';
import BgImg from 'imgs/1d1d/pc/founder.wang.bg.png';
const Wrapper = styled.section`
  /* display: flex; */
  > section {
    display: flex;
    justify-content: center;
    .profile {
      display: flex;
      align-items: center;
      padding-top: 2.2rem;
      height: 100%;
      > img {
        height: 100%;
        margin-right: 6rem;
      }
      .intro {
        color: #2c2c2c;
        .title {
          font-size: 2rem;
          font-weight: 800;
          line-height: 2;
        }
        .subTitle {
          font-size: 1.3rem;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            display: block;
            left: 0.1rem;
            bottom: -1.4rem;
            width: 1.7rem;
            height: 0.3rem;
            background: #ff7c34;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          margin-top: 4.5rem;
          li {
            line-height: 1.6;
            color: #636363;
          }
        }
      }
    }

    &.wang {
      flex-direction: column;
      align-items: center;
      background: url(${BgImg});
      background-size: cover;
      padding-top: 6.2rem;
      > h2 {
        color: #ff7c34;
        font-size: 3.3rem;
        font-weight: 800;
        font-family: 'ITCAvantGardeStd-XLt';
        text-transform: uppercase;
      }
      > h3 {
        margin-top: 1.4rem;
        margin-bottom: 3rem;
        color: #2c2c2c;
        font-size: 2.4rem;
      }
      .profile > img {
        width: 23.1rem;
        /* height: 100%; */
      }
    }
    &.zhang {
      background: #f4f4f4;
      .profile {
        flex-direction: row-reverse;
        > img {
          width: 23.4rem;
          margin-right: 0;
          margin-left: 6rem;
        }
      }
    }
    &.deng {
      background: #eaeaea;
      .profile > img {
        width: 23.8rem;
      }
    }
  }
`;
export default function Founders() {
  return (
    <Wrapper>
      <section className="wang">
        <h2>Founder</h2>
        <h3>创始人</h3>
        <div className="profile">
          <img src={WangImg} alt="Wang Profile" />
          <div className="intro">
            <h4 className="title">王红兵</h4>
            <h5 className="subTitle">壹点壹滴创始人、CEO</h5>
            <ul>
              <li>毕业于清华大学，中欧国际工商学院EMBA学员 </li>
              <li>曾任《中国教育报》记者</li>
              <li>成功研发“天才幼儿园园长办公系统”</li>
              <li>曾出版专著《从幼儿园到清华园》和《红缨的力量》</li>
              <li>2001年，创立北京红缨教育 </li>
              <li>2007年，受北京大学光华管理学院龙军生教授点拨，</li>
              <li>推出“赢在中国”北京红缨幼儿园连锁项目 </li>
              <li>2015年，带领“红缨教育”成功登陆资本市场</li>
              <li>2018年再度出发，创立壹点壹滴</li>
              <li>立志将壹点壹滴打造为一家伟大的幼教互联网公司</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="zhang">
        <div className="profile">
          <img src={ZhangImg} alt="Zhang Profile" />
          <div className="intro">
            <h4 className="title">张缪兴</h4>
            <h5 className="subTitle">总裁、联合创始人</h5>
            <ul>
              <li>北京大学硕士，原伊顿连锁幼儿园信息化总监</li>
              <li>8年国内顶尖幼儿园一线运营管理与信息化经验</li>
              <li>4年的幼教+互联网创业经验</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="deng">
        <div className="profile">
          <img src={DengImg} alt="Deng Profile" />
          <div className="intro">
            <h4 className="title">邓迎锋</h4>
            <h5 className="subTitle">COO、联合创始人</h5>
            <ul>
              <li>曾任北大学园、北师大老教协连锁幼儿园副总经理</li>
              <li>13年早教幼教创业/从业经验，资深幼儿园管理经验</li>
              <li>幼儿园市场营销实战专家</li>
            </ul>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}
