import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import bg from 'imgs/1d1d/pc/ketizu/home_bg_2.png';
import arrow from 'imgs/1d1d/pc/ketizu/home_arrow@2x.png';
import { BgWrap } from './__styled';

const Wrapper = styled.section`
  position: relative;
  font-family: Microsoft YaHei;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.2rem 0 2.5rem;
  .info-box {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      height: 1.75rem;
      font-size: 1.75rem;
      color: #ffffff;
      margin-bottom: 2.7rem;
    }
    h4 {
      width: 38.95rem;
      font-size: 1.1rem;
      color: #ffffff;
      line-height: 2rem;
      text-align: center;
    }
    .btn {
      margin-top: 4.5rem;
      width: 12.6rem;
      height: 1.87rem;
      border: 0.04rem solid #ffffff;
      font-size: 0.9rem;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        width: 0.95rem;
        height: 1.05rem;
        margin-left: 0.15rem;
      }
    }
  }
`;

export default function ResearchBase() {
  return (
    <Wrapper>
      <BgWrap bg={bg} />
      <div className="info-box">
        <h1>课题实验基地</h1>
        <h4>
          为认真贯彻落实习近平总书记关于家庭教育的重要讲话精神，根据教育部《关于加强家庭教育工作的指导意见》要求，经中国教育发展战略学会教师发展专业委员会专家评议、园所资质核查及综合评定，共有百余家幼儿园被评为“信息化背景下的家庭教育研究”实验基地。
        </h4>
        <Link to="/ketizu/research/">
          <div className="btn">
            查看名单
            <img src={arrow} alt="" />
          </div>
        </Link>
      </div>
    </Wrapper>
  );
}
