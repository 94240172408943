import React from 'react';
import styled from 'styled-components';

import MapImg from 'imgs/1d1d/pc/founders.map.png';
const Wrapper = styled.section`
  .map {
    width: 68rem;
    height: 48.5rem;
    margin: 0 auto;
    margin-top: 2rem;
    background: url(${MapImg});
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    > hgroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 7.5rem;
      margin-top: 4.4rem;
      h2 {
        color: #2c2c2c;
        font-size: 2.4rem;
      }
      h3 {
        font-family: 'ITCAvantGardeStd-XLt';
        margin-bottom: 1.8rem;
        color: #ff7c34;
        font-size: 3.3rem;
        font-weight: 800;
        text-transform: uppercase;
      }
    }
    .cards {
      display: flex;
      width: 38.5rem;
      flex-wrap: wrap;
      justify-content: space-between;
      .card {
        background: #fff;
        padding: 1.7rem 1.4rem 1.7rem 1.9rem;
        box-shadow: 0px 0px 18px 0px rgba(111, 111, 111, 0.2);
        border-radius: 1rem;
        line-height: 1.2;
        margin-bottom: 3.5rem;
        > p {
          color: #2c2c2c;
          font-size: 1.3rem;
        }
        &.left {
          width: 14.2rem;
        }
        &.right {
          width: 20.6rem;
        }
        .num {
          font-family: RockoUltraFLF;
          font-size: 2.7rem;
          &.green {
            color: #00b6c6;
          }
          &.pink {
            color: #ff7c34;
          }
          &.blue {
            color: #27abff;
          }
          &.red {
            color: #ff5b5b;
          }
        }
      }
    }
  }
`;
export default function Map() {
  return (
    <Wrapper>
      <div className="map">
        <hgroup>
          <h3>business scale</h3>
          <h2>企业规模</h2>
        </hgroup>
        <div className="cards" data-aos="zoom-in">
          <div className="card left">
            <p>覆盖</p>
            <p className="num green">500+</p>
            <p>区县</p>
          </div>
          <div className="card right">
            <p>成就</p>
            <p className="num pink">500,000+</p>
            <p>优质教师</p>
          </div>
          <div className="card left">
            <p>赋能</p>
            <p className="num blue">20,000+</p>
            <p>幼儿园</p>
          </div>
          <div className="card right">
            <p>影响</p>
            <p className="num red">2,000,000+</p>
            <p>孩子和家庭</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
