import React from 'react';
import { LinksWrap, Title } from './styled';

export default function Links() {
  return (
    <LinksWrap>
      <Title>友情链接</Title>
      <div className="content-box">
        <a href="http://www.moe.gov.cn/" target="_blank" rel="noreferrer">中华人民共和国教育部</a>
        <a href="http://www.cseds.edu.cn/edoas2/zlxh/index.jsp" target="_blank" rel="noreferrer">中国教育发展战略学会</a>
        <a href="http://www.csedst.com/" target="_blank" rel="noreferrer">中国教育发展战略学会教师发展专业委员会</a>
      </div>
    </LinksWrap>
  );
}
