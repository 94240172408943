import React from 'react';
import tabBg1 from 'imgs/1d1d/pc/ketizu/about_tab_bg1.png';
import styled from 'styled-components';
import { BgWrap } from '../__styled';

const ContentWrap = styled.div`
  font-size: 0.9rem;
  color: #565656;
  line-height: 1.6rem;
  text-align: justify;
  & > p {
    color: #565656;
    margin-bottom: 1.8rem;
  }
`;

export default function TabItem1({ id }) {
  return (
    <div className="tab-content-item" id={id} data-index="0">
      <BgWrap bg={tabBg1} />
      <div className="info-box">
        <h1 className="info-box-title">研究背景</h1>
        <ContentWrap>
          <p>
            习近平总书记指出：办好教育事业，家庭、学校、政府、社会都有责任。家庭是人生的第一所学校，家长是孩子的第一任老师，要给孩子讲好“人生第一课”，帮助扣好人生第一粒扣子。教育、妇联等部门要统筹协调社会资源支持服务家庭教育。要积极推动将家庭教育纳入基本公共服务体系，争取专门经费支持，通过家委会、家长学校、家长课堂、购买服务等形式，形成政府、家庭、学校、社会联动的家庭教育工作体系。
          </p>
          <p>近年来，教育部高度重视家庭教育工作。 </p>
          <p>
            2017年，教育部印发《中小学德育工作指南》(教基〔2017〕8 号)，明确将家庭教育纳入德育工作体系，指导各地各校建立家庭教育工作机制，统筹家长委员会、家长学校、家长会、家访、家长开放日、家长接待日等各种家校沟通渠道，丰富家庭教育指导内容，帮助家长提高家教水平。
          </p>
          <p>
            2019 年，中共中央、国务院印发《关于深化教育教学改革全面提高义务教育质量的意见》，要求重视家庭教育，充分发挥学校主导作用，密切家校联系，指导家长理性帮助孩子确定成长目标，克服盲目攀比，防止增加孩子过重课外负担。“十三五”期间，教育部制定了家庭教育指导工作五年规划，举办“家校协同，让孩子健康成长”家庭教育主题宣传活动，发布《家庭教育指导手册》（家长卷和学校卷）、《家长家庭教育基本行为规范》，引导家长树立正确家庭教育观念，掌握科学家庭教育方法，家校协同育人氛围日益浓厚。
          </p>
          <p>
            中国教育发展战略学会是为适应教育事业发展与改革的需要，于 2005 年经教育部、民政部批准成立的国家一级学会。主管单位是中华人民共和国教育部。中国教育发展战略学会是由从事教育战略、规划研究和教育决策、管理的相关机构和人员自愿组成的群众性学术团体，是非营利性的社会组织。
          </p>
          <p>
            中国教育发展战略学会教师发展专业委员会高度重视“十四五”期间幼儿园的家庭教育工作。“幼儿园信息化背景下的家庭教育研究”重点课题就是在这样的背景下诞生。
          </p>
        </ContentWrap>
        <a className="info-box-btn" href="https://www.wjx.cn/jq/102693804.aspx">
          申报课题实验基地
        </a>
      </div>
    </div>
  );
}
