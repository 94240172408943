import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Container from '../../pages/__Container';
import Tabs from '../../pages/__Tabs';

import Banner from '../../pages/__Banner';
import NewsItem from './_NewsItem';
import Pagination from './__Pagination';

import BannerImg from 'imgs/1d1d/pc/banner.news.png';

const Wrapper = styled.div`
  background: rgb(250, 250, 250);
  .sections {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 4.6rem 0;
    padding-bottom: 0;
    width: 66rem;
    position: relative;
    > section:nth-child(2n) {
      background: #fff;
    }
  }
  .page {
    display: flex;
    justify-content: center;
    margin-top: -2rem;
    padding-bottom: 1rem;
  }
`;
export default function News({ data, pageContext, ...rest }) {
  const Data = data.allMongodbSsdmDdarticles.edges.map((item) => item.node);

  console.log('items', data, pageContext, rest);
  const { pageType = 1, detailPrefix } = pageContext;
  return (
    <Container>
      <Banner banner={BannerImg} h1="新闻中心" h2s={['news center']} />

      <Wrapper>
        <div className="sections">
          <Tabs selected={pageType - 1} />
          {Data.map((s) => {
            return <NewsItem {...s} detailPrefix={detailPrefix} key={s.title} />;
          })}
        </div>
        <div className="page">
          <Pagination isCircle={true} {...pageContext} />
        </div>
      </Wrapper>
    </Container>
  );
}
export const query = graphql`
  query($skip: Int!, $limit: Int!, $pageType: Int!) {
    allMongodbSsdmDdarticles(
      skip: $skip
      limit: $limit
      filter: { type: { eq: $pageType } }
      sort: { fields: [isTop, date], order: [DESC, DESC] }
    ) {
      edges {
        node {
          id
          isTop
          date(formatString: "YYYY-MM-DD hh:mm:ss")
          description
          thumbnail
          title
          link
        }
      }
    }
  }
`;
