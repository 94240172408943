import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  div {
    color: #fff;
  width: 10rem;
  min-height: 2rem;
  line-height: 2rem;
  border-radius:0.5rem;
  padding: 0 1rem;
  text-align: center;
  z-index:999;
  background: rgba(0, 0, 0, 0.6);
  }
`;
const Toast = props => {
  const { remove ,text} = props;
  useEffect(() => {
    setTimeout(() => {
      remove();
    }, 1500);
  }, []);
  return <Wrapper><div>{text}</div></Wrapper>;
};
export default Toast;
