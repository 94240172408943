import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import logo from 'imgs/1d1d/pc/ketizu/logo@2x.png';
import logoActive from 'imgs/1d1d/pc/ketizu/logo-active.png';
import { Wrapper } from './styled';

export default function Header() {
  const [isFixed, setIsFixed] = useState(false);

  const ListLink = (props) => (
    <li>
      <Link to={props.to} activeClassName="active">
        {props.children}
      </Link>
    </li>
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 70) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Wrapper isFixed={isFixed}>
      <div className="header">
        <img src={isFixed ? logoActive : logo} alt="" />
        <div className="l-box">
          <ul>
            <ListLink to="/ketizu/">首页</ListLink>
            <ListLink to="/ketizu/about/">关于课题</ListLink>
            <ListLink to="/ketizu/research/">课题实验基地</ListLink>
          </ul>
          <a className="join" href="https://www.wjx.cn/jq/102693804.aspx">
            申请加入
          </a>
        </div>
      </div>
    </Wrapper>
  );
}
