import React from 'react';
import qrcodeImg1 from 'imgs/1d1d/pc/ketizu/qrcode_1@2x.png';
import qrcodeImg2 from 'imgs/1d1d/pc/ketizu/qrcode_2@2x.png';
import { QrcodesWrap, Title } from './styled';

export default function Qrcodes() {
  return (
    <QrcodesWrap>
      <Title>关注公众号</Title>
      <div className="content-box">
        <img src={qrcodeImg1} alt="" className="first" />
        <img src={qrcodeImg2} alt="" />
      </div>
    </QrcodesWrap>
  );
}
